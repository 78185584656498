angular
  .module('starter.business.controllers')
  .controller(
    'leaveRequestCtrl',
    function (
      $scope,
      $rootScope,
      Service,
      DateService,
      Http,
      $ngRedux,
      LeaveAction,
      LeaveService,
      $filter,
      Analytics,
      $state
    ) {
      $state.go('business.leaves')
    }
  )
