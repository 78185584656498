import { useEffect, useState } from 'react'
import { usePaywall } from '../usePaywall'
import { logEvent } from '../../../../../../modules/analytics'

export function useMessage() {
  const { tokenizeStatus, setTokenizeStatus } = usePaywall()

  useEffect(() => {
    // dispatch(setDisabled(true))
    const onMessage = function (event) {
      if (event.origin === (process.env.BPAY_PUBLIC_URL || process.env.NEXT_PUBLIC_BPAY_PUBLIC_URL)) {
        const message = JSON.parse(event.data)
        switch (message.type) {
          case 'init_height':
            const iframeHeight = message.val
            // setHeight(iframeHeight)
            // dispatch(setDisabled(false))
            // setLoadFormDone(true)
            // if (onHeightCallback) {
            //   onHeightCallback(iframeHeight)
            // }
            break
          case 'validation_error':
          case 'invalid_request_error':
          case 'card_error':
          case 'invoke_error':
          case 'tokenization_error':
            // dispatch(setTokenizeStatus({ status: 'error' }))
            // if (onError) {
            //   onError()
            // }

            logEvent(
              window.localStorage.pr_agent_id ? 'Agent Card Tokenisation Failure' : 'Company Card Tokenisation Failure'
            )

            break
          case 'tokenize_success':
            // setShowIframe(false)

            const paymentMethod = message.paymentMethod
            // setLoadFormDone(false)
            // logEvent('Entered card detail(tokenized)')
            // dispatch(setTokenizeStatus({ status: 'success', reference: paymentMethod.reference, paymentMethod }))
            setTokenizeStatus({ status: 'success', reference: paymentMethod.reference, paymentMethod })

            logEvent(
              window.localStorage.pr_agent_id ? 'Agent Card Tokenisation Success' : 'Company Card Tokenisation Success'
            )
            break
        }
      }
    }
    window.addEventListener('message', onMessage, false)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])
}
