import moment from 'moment'
import {
  getAllActiveEmployees,
  hideAgentBanner,
  setInitialState,
  setIsAgent,
  setModals,
  showAgentBanner,
  hideAgentPriceLockInBanner
} from '../../reducers/appSlice'
import {
  hideSubBanner,
  setOpenModalUpdateSubscription,
  setAllEmployee,
  setCurrentClient
} from '../../reducers/subscriptionSlice'
import { setSubscriptionState } from '/reducers/subscriptionSlice'
import { agentInit, setModalSubscription } from '/src/scheduling/react-subscription/utils/share'

angular
  .module('starter.agent.controllers')
  .controller(
    'agentCtrl',
    function (
      $scope,
      $rootScope,
      $stateParams,
      Http,
      $window,
      Service,
      $timeout,
      Logout,
      $state,
      Analytics,
      Http2,
      $ngRedux,
      HttpPromiseAndLocalStorage,
      API_ENDPOINT,
      UserAuthFactory,
      $location
    ) {
      $rootScope.$state = $state
      $scope.modal = {}
      $scope.subscription = {}
      $scope.company = {}
      $rootScope.isRedirectedFromV2 = false
      $scope.isOnReferralProgramPage = false
      $scope.isGrowthbookLoading = true
      $ngRedux.connect((state) => state.subscription)($scope.subscription)
      $ngRedux.connect((state) => ({ nonPayment: state.subscription.subscriptionError }))($scope)
      $ngRedux.connect((state) => ({ shouldShowAgentProgramBanner: state.app.shouldShowAgentProgramBanner }))($scope)
      $ngRedux.connect((state) => ({ gbFeatures: state.app.gbFeatures }))($scope)
      $ngRedux.connect((state) => state.app.company || {})($scope.company)

      // Redirect agent to V2 if agent_revamp_full_rollout is true
      $scope.$watch('gbFeatures', () => {
        if (Object.keys($scope.gbFeatures).length <= 0) return
        if ($scope.gbFeatures.agent_revamp_full_rollout && !$location.path().includes('connect-client')) {
          const DEV_V2_URL = process.env.DEV_V2
          const token = $window.localStorage.pr_token
          const expires = $window.localStorage.pr_expires
          $window.location.replace(`${DEV_V2_URL}/agent/clients?t=${token}&e=${expires}&userType=agent`)
        } else {
          $scope.isGrowthbookLoading = false
        }
      })
      delete $window.localStorage.pr_client_id
      $scope.reactState = {
        HttpPromise: HttpPromiseAndLocalStorage,
        helpers: Service,
        API_ENDPOINT: API_ENDPOINT
      }

      // disable demo data
      delete $window.localStorage.demo
      $rootScope.demo = false

      // initiate client data
      delete $rootScope.user
      delete $rootScope.company
      delete $rootScope.employees
      delete $rootScope.payRuns
      delete $rootScope.leaves
      delete $rootScope.holidays
      $scope.agentData = {}
      if (
        ($stateParams.redirect === 'agent.bulkRun.selectPayroll' && $stateParams.isRedirectedFromV2 === 'true') ||
        $stateParams.isRedirectedFromV2 === 'true'
      ) {
        $rootScope.isRedirectedFromV2 = true
      }

      if ($location.path() === '/agent/referral-program') {
        $scope.isOnReferralProgramPage = true
      }
      const routeListener = $rootScope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
        if ($location.path() === '/agent/referral-program') {
          $scope.isOnReferralProgramPage = true
        } else {
          $scope.isOnReferralProgramPage = false
        }
      })

      $rootScope.$on('$destroy', routeListener)

      $scope.goToClientPortal = function (clientId) {
        $window.localStorage.pr_client_id = clientId
        $ngRedux.dispatch(setCurrentClient(clientId))
        $state.go('business.run.overview')
      }

      const init = (callback) => {
        $scope.agentData = {}
        $rootScope.clients = ['no data']

        $ngRedux
          .dispatch(agentInit({ Http2 }))
          .unwrap()
          .then((res) => {
            $ngRedux.connect((state) => {
              return {
                isAgent: state.app.isAgent,
                allActiveEmployees: getAllActiveEmployees(state)
              }
            })($rootScope)
            $ngRedux.dispatch(setIsAgent(true))

            $window.localStorage.pr_agent_id = res.agent._id
            $window.localStorage.pr_agent = JSON.stringify(res.agent)
            $scope.agentData = Service.sanitizeObj(res)
            $scope.agentIndex = $scope.agentData.clients.findIndex((x) => x._id === $scope.agentData?.agent?._id)
            $rootScope.agentUser = Service.sanitizeObj(res.agentUser)
            $rootScope.agent = Service.sanitizeObj(res.agent)
            $rootScope.clients = Service.sanitizeObj(res.clients)
            Analytics.setUserId(res.agent._id)
            Analytics.setProperties(
              {
                email: res.agentUser.e,
                first_name: res.agentUser.f,
                last_name: res.agentUser.l,
                company: res.agent.c,
                company_id: res.agent._id,
                account_created_time: res.agent.at,
                ...(res.agentUser.q1 === 'a' && res.agentUser.q2 && { num_of_customers_range: res.agentUser.q2 }),
                num_of_customers: res.clients.length || 0,
                is_agent: 'true',
                user_status: res.agentUser.lv === 'm' ? 'Member' : 'Admin',
                white_label_option: (!!res.agent.ag_u).toString()
              },
              res.agent._id
            )
            Http(
              {
                method: 'get',
                api: '/v1/initial/',
                disableErrorMsg: true
              },
              async (res) => {
                $ngRedux.dispatch(setInitialState(res))
                $rootScope.appState = res
                try {
                  window.Intercom('boot', {
                    app_id: 'z7jnrfnh',
                    user_id: res.user._id,
                    name: `${res.user.f} ${res.user.l}`,
                    email: res.user.e,
                    created_at: new Date(res.user.at).getTime(),
                    frontend_version: APP_VERSION
                  })
                } catch (e) {
                  console.log(e)
                }
              }
            )

            return Http2({
              method: 'post',
              api: '/v1/agent/get/last-pay-runs',
              data: {
                companyIds: res.clients.map((x) => x._id)
              }
            })
          })
          .then((res) => {
            for (let client of $rootScope.clients) {
              client.payRun = res.payRuns.find((x) => client._id === x.ci)
              if (!client.payRun) {
                // 없는 data 는 no data yet 으로 만들어 주기 위해서
                client.payRun = []
              } else {
                client.payRun = [client.payRun]
              }
            }
            $ngRedux.dispatch(setSubscriptionState($rootScope.clients))
            if (callback) {
              callback()
            }
          })
      }

      // has token from url, call init after setting up tokens
      if ($stateParams.t) {
        UserAuthFactory.loginSuccess({
          isRedirect: !!$stateParams.redirect,
          token: {
            token: $stateParams.t,
            expires: parseInt($stateParams.e),
            user: $stateParams.u
          }
        }).then(() => {
          $rootScope.isAgent = true
          $window.localStorage.pr_agent_id = $stateParams.u

          init(() => {
            if ($stateParams.clientId) {
              $scope.goToClientPortal($stateParams.clientId)
            } else if ($stateParams.redirect) {
              $state.go($stateParams.redirect, {}, { inherit: false }) // clear the search params
            } else {
              // GTM
              if ($stateParams.newUser === 'true' && dataLayer && typeof dataLayer.push === 'function') {
                dataLayer.push({
                  event: 'SignupSuccess',
                  source: $stateParams.s
                })
              }

              $state.go('agent.clients', {}, { inherit: false }) // clear the search params
            }
          })
        })
      } else {
        init()
      }

      $scope.init = () => {
        init()
      }

      $scope.onJoinAgentProgram = () => {
        Http(
          {
            method: 'post',
            api: '/v1/agent/agent-program/join'
          },
          () => {
            Analytics.logEvent('Agent program: User joins agent program via join agent program banner')
            $scope.onCloseAgentProgramBanner()
            init(() => {
              $state.go('agent.referral-program')
            })
            $ngRedux.dispatch(
              setModals([
                {
                  kind: 'AgentProgramWelcomeModal',
                  channel: 'local',
                  options: {
                    overlay: true
                  }
                }
              ])
            )
          }
        )
      }

      $scope.$watch('gbFeatures', () => {
        if ($scope.gbFeatures && $scope.gbFeatures?.agent_program_banner && !$rootScope.agent?.agentProgramJoinDate) {
          const agentProgramBanner = localStorage.getItem('agent_program_banner')
          if (agentProgramBanner !== null) {
            const parsedAgentProgramBanner = JSON.parse(agentProgramBanner)
            if (parsedAgentProgramBanner) {
              $ngRedux.dispatch(showAgentBanner())
            } else {
              $ngRedux.dispatch(hideAgentBanner())
            }
          } else {
            localStorage.setItem('agent_program_banner', 'true')
            $ngRedux.dispatch(showAgentBanner())
          }
        }
      })

      $scope.onCloseAgentProgramBanner = () => {
        $ngRedux.dispatch(hideAgentBanner())
        localStorage.setItem('agent_program_banner', 'false')
      }

      $scope.onCloseAgentPriceLockInBanner = () => {
        $ngRedux.dispatch(hideAgentPriceLockInBanner())
        localStorage.setItem('agent_price_lock_in_banner', 'false')
      }

      $scope.hideNonPayment = () => {
        $('#NonPayment').slideUp(350)
        setTimeout(() => {
          $ngRedux.dispatch(hideSubBanner())
        }, 500)
      }

      $scope.hideBannerExpire = () => {
        $('#bannerExpire').slideUp(350)
      }

      $scope.amplitudeEvent = (triggerPoint) => {
        Analytics.logEvent('click the trigger points', {
          trigger_point: triggerPoint,
          company_id: $window.localStorage.pr_client_id
        })
      }

      $scope.choosePlan = () => {
        // $state.go('subscription');
        const invoices = $ngRedux.getState().subscription.listInvoices
        if (invoices && invoices.length > 0) {
          // has subscription before
          $ngRedux.dispatch(setOpenModalUpdateSubscription(true))
        } else {
          $scope.amplitudeEvent('Banner')
          $ngRedux.dispatch(
            setModalSubscription({
              source: 'AgentBanner'
            })
          )
        }
      }

      $scope.goToSubscription = function () {
        $state.go('update-subscription')
      }

      $scope.logout = function () {
        $window.localStorage.removeItem('url_client_pay')
        $timeout(function () {
          Service.hideLoading()
          $rootScope.reseller = {}
          localStorage.clear()
          $window.localStorage.ib_reseller_loggedin = null
          $state.go('home.main')
          window.Sentry?.setUser(null)
          Logout()
        }, 300)
      }

      $scope.copyIdToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
          function () {
            Service.alert('Copied', 'Copying to clipboard was successful!', 'success')
          },
          function (err) {
            console.error('Async: Could not copy text: ', err)
          }
        )
      }

      $scope.slideUpMenuOnMobile = function () {
        $('.menu-and-user').slideUp()
      }
      $scope.$on('setAgentData', (evt, data) => {
        $scope.agentData = data
        init()
      })
    }
  )
