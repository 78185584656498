import clsx from 'clsx'
import React from 'react'
import moment from 'moment-timezone'
import { usePaywall } from '../../usePaywall'
import * as s from './styles'
import { formatPeriodLength, to$ } from '../../../../../../utils'
import { formatMoney, formattedSchedule } from '../../../../../../react-subscription/utils'
import { LINKS, PERIOD_NAMES } from '../../../../../../constant'

const Item = ({ title = '', description = '', children }) => {
  return (
    <div
      className={clsx(s.orderItemContainer, {
        'items-center': (title && !description) || (!title && description),
        'items-start': title && description
      })}
    >
      <div className="!mr-[20%]">
        {title && <p className={s.orderItemTitle}>{title}</p>}
        {description && (
          <p
            className={clsx(s.orderItemDescription, {
              '!leading-[27px]': title,
              '!leading-[19px]': !title
            })}
          >
            {description}
          </p>
        )}
      </div>
      {children}
    </div>
  )
}

export function OrderSummary({ isV2 }) {
  const { seats, setSeats, period, plan, prices, taxExclusiveInfo, discount } = usePaywall()

  const momentDiscountEnd = discount ? moment(discount.end) : undefined
  const monthDiff = discount ? momentDiscountEnd.diff(moment(), 'month') : undefined
  const isNoDiscount = !discount

  const total = formatMoney(plan?.total)
  let length = formatPeriodLength(moment().tz('Australia/Sydney').startOf('day'), plan?.nextBillingDate, true)
  const lengthStr = length === 'today' ? 'today.' : `after the ${length}-day trial.`

  const detail = formattedSchedule(plan?.paymentSchedules, plan)
  const hasRollOver = plan?.paymentSchedules[0].rollover

  return (
    <div className={s.halfItem}>
      <h2 className={s.title}>Order Summary</h2>
      <div className={s.orderPlanContainer}>
        <Item description="Selected plan">
          <p className={s.detailTextMedium}>Standard</p>
        </Item>
        <Item description="Billing schedule">
          <p className={s.detailTextMedium}>{PERIOD_NAMES[period]}</p>
        </Item>
        <Item description="Number of employees">
          <input
            className={isV2 ? s.v2InputSeat : s.inputSeat}
            type="number"
            min={1}
            value={seats}
            onChange={(e) => setSeats(e.target.value)}
          />
        </Item>
      </div>
      <div className={s.divider} />
      <div className={s.orderPriceContainer}>
        {detail?.map((d, id) => (
          <Item key={id} title={d.content} description={d.details}>
            <p className={s.detailTextNormal}>
              {formatMoney(d.price)}
              {hasRollOver && id === 0 && <span title="rollovered">*</span>}
            </p>
          </Item>
        ))}
        <Item title="GST">
          <p className={s.detailTextNormal}>{formatMoney(plan?.gst || 0)}</p>
        </Item>
      </div>
      <div className={s.divider} />
      <div className={s.orderDescriptionContainer}>
        <div>
          <Item title="Total">
            <p className={s.detailTextMedium}>{total}</p>
          </Item>
          {hasRollOver && (
            <p className={s.rolloverWarn}>
              {`You will be automatically billed ${total} at your next billing cycle on ${moment(
                plan?.paymentSchedules[0].end
              ).format('DD MMM YYYY')}. Cancel anytime.`}
            </p>
          )}
        </div>
        <p className={s.detailTextNormal}>
          You will be billed {total} {lengthStr}
        </p>
        <p className={s.description}>
          {isNoDiscount ? (
            <>{to$(prices?.normal.unitPrice)}/month per employee with a </>
          ) : (
            <>
              {prices?.discount &&
                `Initial ${monthDiff}-month discount of ${to$(
                  prices?.discount.cost
                )}/month per employee applicable for ${
                  monthDiff >= 3 ? 'first three consecutive months' : 'first month'
                }. Thereafter,`}
              {!prices?.discount && 'Start with'} {to$(prices?.normal.unitPrice)}/month per employee with a{' '}
            </>
          )}
          <span className="!font-semibold">minimum spend</span> of {to$(prices?.normal.minCost)}/month. Billed{' '}
          {PERIOD_NAMES[period].toLowerCase()}.
        </p>
        <p className={s.description}>
          Plan automatically renews each {period} until cancelled. All prices {taxExclusiveInfo} of GST.
        </p>
        <p className={s.description}>
          By proceeding with the order, you accept our{' '}
          <a className="!text-[#016ccf]" href={LINKS.TERMS_CONDITIONS.url} target="_blank">
            Terms & Conditions.
          </a>
        </p>
      </div>
    </div>
  )
}
