/* FAQ */
export const FAQContainer = 'bg-white !mt-[60px]'
export const FAQtitle = '!font-bold !text-[32px] text-center !font-avenirNext !leading-[44px]'
export const FAQItems = '!py-[24px] flex flex-col gap-4'

/* Paywall */
export const paywallContainer =
  'reset-styles bg-white w-screen h-screen -mt-modalTopSpace !px-[8vw] relative pb-5 overflow-scroll'
export const stepWrapper = '!pt-[47px] flex justify-center items-center !mb-[26px]'
export const stepDivider = '!border-[0.5px] !border-solid w-[73px] !border-[#C6C6C6] !mx-2'
export const btnClose = 'absolute right-0 top-0 !mt-7 !mr-[38px] cursor-pointer'
export const title = '!text-[32px] !font-bold !font-avenirNext !leading-[44px] !text-center'
export const periodWrapper = '!text-center !pt-1 flex flex-col items-center !mb-[27px]'
export const description = "!font-['Rubik'] !text-[18px] !font-normal !leading-[21px] !text-[#1E2022]"
export const periodContainer =
  'border border-solid-non-color !border-periodBorder w-fit rounded-[4px] !px-[4px] !py-[5px] !mt-[22px]'
export const loading = '!text-primary !block !mt-[200px] !mb-[200px] !ml-auto !mr-auto'
export const googleReview = 'flex justify-center !mt-[19px] !mb-24'
export const sticky = 'sticky bottom-0'
export const floatingButton =
  'bg-[#EDF5F5] w-[112px] h-[42px] gap-[18px] flex justify-center items-center !rounded-[5px] font-sansRubik font-medium !text-primary text-[15px] cursor-pointer translate-x-[calc(-8vw+28px)] translate-y-[-12px]' // move left by the padding amounts (8vw), then right +28px
export const floatingIcon = (faqVisible) => (faqVisible ? 'rotate-180' : 'rotate-0')
export const v2PaywallContainer = 'reset-styles bg-white w-full h-dvh !px-[8vw] relative pb-5 overflow-auto'
