import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { prepareHeaders } from './prepareHeaders'

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE, prepareHeaders }),
  endpoints: (build) => ({
    update: build.mutation({
      query: ({ id, ...patch }) => ({
        url: '/v1/company/update',
        method: 'POST',
        body: patch
      })
    })
  })
})

export const { useMutation } = companyApi.endpoints.update
