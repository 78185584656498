"use strict";
exports.__esModule = true;
exports.STORAGE_KEYS = void 0;
var STORAGE_KEYS = {
    APPLE_CALENDAR_SYNC: '@payroller:syncAppleCalendar',
    APP_THEME: '@appTheme',
    PLATFORM: '@appPlatform',
    APP_BUNDLE: '@appBundle',
    PEOPLE_MOBILE: '@isPeopleMobile',
    WHATS_NEW_POPUP: {
        roster: 'rosterWhatsNewPopup',
        timesheet: 'timesheetWhatsNewPopup',
        leave: 'leaveWhatsNewPopup',
        conflict: 'conflictWhatsNewPopup',
        addSheet: 'addSheetWhatsNewPopup',
        unscheduled: 'unscheduledWhatsNewPopup'
    },
    SANDBOX_MODE: 'sandboxMode',
    USER_TYPE: 'userType'
};
exports.STORAGE_KEYS = STORAGE_KEYS;
