"use strict";
exports.__esModule = true;
exports.eventNames = void 0;
exports.eventNames = {
    AppOpen: 'Application Opened',
    AuthLoginCompleted: 'Auth Login Completed',
    AuthForgotPasswordVisited: 'Auth Forgot Password Visited',
    AuthSignupVisited: 'Auth Signup Visited',
    AuthResetPasswordSent: 'Auth Reset Password Sent',
    AuthForPassLoginRedirect: 'Auth ForPass Login Redirected',
    EmployeeAdditionInitiated: 'Employee Addition Initiated',
    EmployeeAdditionCancelled: 'Employee Addition Cancelled',
    EmployeeAdditionStep1Completed: 'Employee details',
    EmployeeAdditionStep2Completed: 'Employment details',
    EmployeeAdditionStep3Completed: 'Send Employee invitation',
    AuthSignupButtonClicked: 'Auth Signup Button Clicked',
    AuthEmailVerificationResent: 'Auth Email Verification Resent',
    BPOnboardStep1Completed: 'BookipiPeople Onboard Step 1 Completed',
    BPOnboardStep2Completed: 'BookipiPeople Onboard Step 2 Completed',
    RosterView: 'Roster Page Viewed',
    RosterAddShift: 'Roster Shift Initiated',
    RosterShiftCreated: 'Roster Shift Created',
    RosterShiftCanceled: 'Roster Shift Canceled',
    RosterViewShift: 'Roster Shift Viewed',
    RosterShiftEdited: 'Roster Shift Edited',
    RosterEditShift: 'Roster Shift Edit Initiated',
    RosterEditShiftSaved: 'Roster Shift Edit Saved',
    RosterEditShiftCanceled: 'Roster Shift Edit Cancelled',
    RosterDeleteShift: 'Roster Shift Delete Initiated',
    RosterDeleteShiftConfirmed: 'Roster Shift Deleted',
    RosterConflictView: 'Roster Conflict Viewed',
    RosterConflictResolved: 'Roster Conflict Resolved',
    RosterPublish: {
        Cancelled: 'Roster Publish Cancelled',
        PublishInitiated: 'Roster Publish Initiated',
        Step1Completed: 'Roster Publish Step 1 Completed',
        Step1Canceled: 'Roster Publish Step 1 Canceled',
        Step2Completed: 'Roster Publish Step 2 Completed',
        Step2Canceled: 'Roster Publish Step 2 Canceled',
        Step3Completed: 'Roster Published',
        Step3Canceled: 'Roster Publish Step 3 Canceled'
    },
    Leave: {
        PageViewed: 'Leave Page Viewed',
        AdditionInitiated: 'Leave Addition Initiated',
        AdditionCancelled: 'Leave Addition Cancelled',
        Created: 'Leave Created',
        Deleted: 'Leave Deleted',
        Viewed: 'Leave Viewed',
        Cancelled: 'Leave Cancelled',
        Approved: 'Leave Approved',
        Declined: 'Leave Declined'
    },
    Setting: {
        PageViewed: 'Settings Page Viewed',
        BusinessViewed: 'Settings Business Viewed',
        BusinessEdited: 'Settings Business Edited',
        ChangePasswordInitiated: 'Settings Change Password Initiated',
        ChangePasswordCompleted: 'Settings Change Password Completed',
        ProfileEdited: 'Settings Profile Edited',
        ChangeEmailInitiated: 'Settings Change Email Initiated',
        ChangeEmailCompleted: 'Settings Change Email Completed',
        SupportInitiated: 'Settings Support Initiated',
        FeatureRequestInitiated: 'Feature Request Initiated',
        SettingViewed: 'Settings Business Setting Viewed',
        SettingEdited: 'Settings Business Setting Edited',
        SystemViewed: 'Settings System Viewed',
        SystemEdited: 'Settings System Edited',
        DeleteAccountInitiated: 'Delete Account Initiated',
        DeleteAccountConfirmed: 'Delete Account Confirmed',
        DeleteAccountCompleted: 'Delete Account Completed',
        SuperStreamDisconnect: 'Superstream disconnect'
    },
    Auth: {
        SignoutCompleted: 'Auth Signout Completed'
    },
    Timesheet: {
        PageViewed: 'Timesheet Page Viewed',
        AdditionInitiated: 'Timesheet Addition Initiated',
        Created: 'Timesheet Created',
        Viewed: 'Timesheet Viewed',
        EditInitiated: 'Timesheet Edit Initiated',
        Edited: 'Timesheet Edited',
        Approved: 'Timesheet Approved',
        Unapproved: 'Timesheet Unapproved',
        AllApproved: ' Timesheet All Approved',
        Discarded: 'Timesheet Discarded',
        Restored: 'Timesheet Restored',
        PageViewedCancelled: 'View Timesheet Cancelled'
    },
    Employee: {
        ClockInCompleted: 'Clock-In Completed',
        ClockOutCompleted: 'Clock-Out Completed',
        ShiftNotificationAccepted: 'Shift Notification Accepted',
        ShiftNotificationDeclined: 'Shift Notification Declined',
        ShiftNotificationSeeMore: 'Shift Notification See More',
        ShiftNotificationSeeMoreDismissed: 'Shift Notification See More Dismissed',
        SettingsSwitchEmployerInitiated: 'Settings Switch Employer Initiated',
        SettingsSwitchEmployerCompleted: 'Settings Switch Employer Completed',
        AuthSignupCompleted: 'Auth Employee Signup Completed',
        AddShiftCalendar: 'Add Shift To Calendar Clicked',
        DismissShiftCalendar: 'Add Shift To Calendar Dismissed'
    },
    Payslip: {
        PageViewed: 'Payslip Page Viewed',
        Opened: 'Payslip Opened',
        DownloadInitiated: 'Payslip Download Initiated'
    },
    ActivationInitiated: 'Activation Initiated',
    PeoplePageViewed: 'People Page Viewed',
    Notification: {
        Viewed: 'Notification Viewed',
        CreateRosterInitiated: 'Notification Create Roster Initiated',
        PublishRosterInitiated: 'Notification Publish Initiated'
    }
};
