import React from 'react'
import { OrderSummary } from './comps/OrderSummary'
import { PaymentDetails } from './comps/PaymentDetails'
import * as s from './styles'

export function PaymentSummary({ isV2, onSuccessPayment }) {
  return (
    <div className={s.flexRowCenter}>
      <div className={isV2 ? s.v2PaymentSummaryContainer : s.paymentSummaryContainer}>
        <OrderSummary isV2={isV2} />
        <PaymentDetails isV2={isV2} onSuccessPayment={onSuccessPayment} />
      </div>
    </div>
  )
}
