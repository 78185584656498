import React, { useEffect, useRef, useState } from 'react'
import { usePaywall } from '../../usePaywall'
import { useMessage } from '../useMessage'
import { useProgress } from '../useProgress'
import { StripeElements } from './StripeElements'
import { CircularProgress } from '@material-ui/core'
import * as s from './styles'
import { GaEvent } from '../../../../../../components/GaEvent'
import { logEvent } from '../../../../../../../modules/analytics'
import { useSelector } from 'react-redux'
import { PROGRESS } from '../../../../../../constant'
import AlertConfirm from '../../../../../AlertConfirm'
import { useModal } from '../../../../../../hooks'
import { isValidMobileSubscription } from '../../../../../../../utils/subscription'

export function PaymentDetails({ isV2, onSuccessPayment }) {
  useMessage()

  const { tokenizeStatus, seats, prices, isTrialPlan, setTokenizeStatus } = usePaywall()
  const stripeEl = useRef(null)
  const [isSwitchSubModalVisible, setSwitchSubModalVisibility] = useState(false)

  const { isLoading, progress, isError } = useProgress()
  const { hideModal } = useModal()

  const { subscriptionStatus } = useSelector((state) => ({
    subscriptionStatus: state.app.subscriptionStatus
  }))
  const { gbFeatures } = useSelector((state) => ({
    gbFeatures: state.app.gbFeatures
  }))

  const { delayed_tokenisation: delayedTokenisation } = gbFeatures

  useEffect(() => {
    const hasValidMobileSubscription = isValidMobileSubscription(subscriptionStatus)
    if (progress === PROGRESS.SUCCESS && hasValidMobileSubscription && !subscriptionStatus.webapp.isValid) {
      setSwitchSubModalVisibility(true)
    } else if (progress === PROGRESS.SUCCESS) {
      hideModal('Paywall')
      // Check if the user is in settings-v2 page
      // If yes, refetch initial data
      if (window.location.href.includes('settings-v2')) {
        window.postMessage('refetch-initial-data')
      }
      // add post message here to refresh v2 initial
      if (isV2) {
        onSuccessPayment()
      }
    }
  }, [progress, subscriptionStatus])

  useEffect(() => {
    if (!isError) return
    // reload stripe's iframe
    setTokenizeStatus('error')
    stripeEl.current.src += ''
  }, [isError])

  return (
    <>
      <div className={s.halfItem}>
        <h2 className={s.title}>Payment details</h2>
        {process.env.GUI_DEBUG === 'DEBUG' && <div className="!text-primary">[payment progress: {progress}]</div>}

        <StripeElements ref={stripeEl} />
        <button
          className={s.btnConfirm}
          onClick={() => {
            if (tokenizeStatus !== 'success') {
              stripeEl.current.contentWindow.postMessage(
                'checkout',
                process.env.BPAY_PUBLIC_URL || process.env.NEXT_PUBLIC_BPAY_PUBLIC_URL
              )

              if (delayedTokenisation) {
                logEvent('Submit payment on summary screen (test group)')
              } else {
                logEvent(
                  window.localStorage.pr_agent_id ? 'Agent Card Tokenise Started' : 'Company Card Tokenise Started'
                )
              }
            }
          }}
        >
          {isLoading && <CircularProgress size={24} thickness={5} className="!text-white" />}
          {!isLoading && 'Confirm'}
        </button>

        <GaEvent
          name="begin_checkout"
          items={[
            {
              item_id: isTrialPlan ? 'free_trial' : 'subscription',
              item_name: isTrialPlan ? 'Free Trial' : 'Subscription',
              item_category: 'Annual',
              coupon: 'VARIANTC',
              price: prices?.discount?.unitPrice || prices?.normal.unitPrice,
              currency: 'AUD',
              quantity: seats
            }
          ]}
          description="Checkout screen (credit card detail)"
        />
      </div>
      <AlertConfirm
        open={isSwitchSubModalVisible}
        hasClose={false}
        title="Don't forget to cancel on Mobile"
        message="Ensure you cancel your subscription on the Mobile app to avoid any duplicate charges. Please refer to the guideline below for assistance"
        titleStyle="!font-inter text-lg font-medium text-inputLabelGray font-inter"
        messageStyle="!mt-3 text-inputTextGray !font-inter"
        buttonArr={[
          {
            label: 'Confirm',
            className:
              'px-4 py-2.5 text-sm font-font-normal text-center text-white rounded-lg cursor-pointer !bg-primary hover:opacity-70 w-full',
            onClick: () => {
              hideModal('Paywall')
              setSwitchSubModalVisibility(false)
            }
          }
        ]}
      />
    </>
  )
}
