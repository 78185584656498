import React, { Suspense, useState } from 'react'
import { createRoot } from 'react-dom/client'
import store from '../store'
import { Provider } from 'react-redux'
import AngularContext from '../contexts/angular'
import { GaInit } from './components/GaInit.js'
import { PayrollerGrowthBookProvider } from './components/GrowthBook'
import { GlobalModal } from './components/Modal'
import {
  Setup2faCSS,
  ForgotPassword,
  VerifyViaEmail,
  VerifyViaGoogleAuth,
  ResetPassword,
  PayrollerContextProvider,
  EmployeeNotification,
  EmployeeRoster,
  TimesheetPage,
  EmployeeTimesheets,
  LeavesPage,
  EmployeeLeave,
  RosterPage,
  DiscountOfferModal
} from '@payroller/pds/client'
import { Ping, AgentBanner, Dashboard as AgentReferralDashboard, AgentPriceLockInBanner } from '@payroller/pds'
import SignUp from './auth/SignUp'
import Login from './auth/Login'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useAlerts } from './hooks/useAlerts.js'
import { useModal } from './hooks/useModal.js'
import { getSearchParams } from '@bookipi/utils'
import { FlowbiteProvider } from '../react-global/flowbite'

const MobileNavigation = React.lazy(() => import('./react-mobile-navigation'))
const RosterComponent = React.lazy(() => import('./react-roster/components/roster.js'))
const TimesheetComponent = React.lazy(() => import('./react-timesheet/components/timesheet.js'))
const TimesheetModalComponent = React.lazy(() => import('./react-timesheet-modal/timesheetModal.js'))
const UpgradeSuperModal = React.lazy(() => import('./react-super-modal/upgradeSuperModal.js'))
const SelectIndustryModal = React.lazy(() => import('./react-global-modal/select-industry-modal.js'))
const STPReportMigrationModal = React.lazy(() => import('./react-global-modal/stp-report-migration-modal.js'))
const NotificationDrawer = React.lazy(() => import('./react-notifications/notificationDrawer.js'))
const DowngradeSuperModal = React.lazy(() => import('./react-super-modal/downgradeSuperModal.js'))
const UnsubscribeComponent = React.lazy(() => import('./unsubscription/index.js'))
const SubscriptionModal = React.lazy(() => import('./react-subscription-modal/subscription-modal'))
const STPTransitionModalComponent = React.lazy(() => import('./react-stp-transition/stpTransitionModal'))
const ReactInviteEmployeeModal = React.lazy(() => import('./react-invite-employee-modal/index.js'))
const ReactInviteEmployeeButton = React.lazy(() => import('./react-invite-employee-button/index.js'))
const PaymentInvitationPage = React.lazy(() => import('./react-payment-invitation-page'))
const UpdateSubscriptionModal = React.lazy(() =>
  import('./react-subscription/views/update-subscription-modal/index.js')
)
const SettingPaymentMethod = React.lazy(() => import('./react-agent/views/Settings'))
const ActionClient = React.lazy(() => import('./react-agent/views/Clients/components/ActionClient.js'))
const SubscriptionStatus = React.lazy(() => import('./react-agent/views/Clients/components/SubscriptionStatus.js'))
const InviteClientPopup = React.lazy(() => import('./react-agent/views/Clients/components/InviteClientPopup.js'))
const SubscriptionSettings = React.lazy(() => import('./react-agent/views/Settings/SubscriptionSettings'))
const AddAdditionalSeatsPopup = React.lazy(() => import('./react-agent/views/Clients/components/AddSeats.js'))
const PaymentSummaryModal = React.lazy(() =>
  import('./react-agent/views/Clients/components/PaymentSummaryModal/index.js')
)
const SpaceshipModal = React.lazy(() => import('./react-spaceship-modal/spaceshipModal.js'))
const MyCrap = React.lazy(() => import('./test/myCrap.js'))
const To2018modal = React.lazy(() => import('./test/to2018modal.js'))
const GoogleLoginButton = React.lazy(() => import('./react-google-login-button'))
const LocalModal = React.lazy(() => import('./components/Modal'))
const Alerts = React.lazy(() => import('./components/Alerts'))

const AddressSuggetion = React.lazy(() => import('./react-address-suggestion'))

const SubscriptionBanner = React.lazy(() => import('./components/SubscriptionBanner'))
const PriceChangeBanner = React.lazy(() => import('./components/PriceChangeBanner'))
const VersionTwoOptInBanner = React.lazy(() => import('./components/VersionTwoOptInBanner'))
const PeoplePageOptInBanner = React.lazy(() => import('./components/PeoplePageOptInBanner'))
const RevampPageOptInBanner = React.lazy(() => import('./components/ReportOptInBanner'))
const DynamicBanner = React.lazy(() => import('./components/DynamicBanner'))
const DemoBanner = React.lazy(() => import('./components/DemoBanner'))
const HelpSidebar = React.lazy(() => import('./react-common/HelpSidebar'))

const CreateTestAccount = React.lazy(() => import('./react-create-test-account'))
const OnboardingTutorialButton = React.lazy(() => import('./react-onboarding-tutorial/OnboardingTutorialButton'))
const CompanySetupControl = React.lazy(() => import('./react-onboarding-tutorial/CompanySetupControl'))
const ImportEmployees = React.lazy(() => import('./react-import/ImportEmployees'))
const FYFilter = React.lazy(() => import('./components/FYFilter'))
const PdfModal = React.lazy(() => import('./components/PdfModal'))
const Navbar = React.lazy(() => import('./components/Navbar'))

export const passedRoots = new WeakMap()
export function Render(componentName, props, element) {
  const merged = {
    rosters: RosterPage,
    oldRosters: RosterComponent,
    timesheets: TimesheetPage,
    PeoplePageOptInBanner,
    RevampPageOptInBanner,
    oldTimesheet: TimesheetComponent,
    timesheetModal: TimesheetModalComponent,
    UpgradeSuperModal: UpgradeSuperModal,
    notifications: NotificationDrawer,
    DowngradeSuperModal: DowngradeSuperModal,
    STPTransitionModal: STPTransitionModalComponent,
    unsubscribe: UnsubscribeComponent,
    SubscriptionModal: SubscriptionModal,
    ReactInviteEmployeeModal: ReactInviteEmployeeModal,
    ReactInviteEmployeeButton,
    PaymentInvitationPage: PaymentInvitationPage,
    UpdateSubscriptionModal: UpdateSubscriptionModal,
    SettingPaymentMethod: SettingPaymentMethod,
    ActionClient: ActionClient,
    SubscriptionStatus: SubscriptionStatus,
    MyCrap,
    To2018modal,
    SelectIndustryModal,
    STPReportMigrationModal,
    InviteClientPopup: InviteClientPopup,
    SubscriptionSettings: SubscriptionSettings,
    AddAdditionalSeatsPopup: AddAdditionalSeatsPopup,
    FYFilter,
    PdfModal,
    PaymentSummaryModal,
    SpaceshipModal,
    GoogleLoginButton,
    LocalModal,
    Alerts,
    AddressSuggetion,
    SubscriptionBanner,
    PriceChangeBanner,
    VersionTwoOptInBanner,
    AgentBanner,
    AgentPriceLockInBanner,
    MobileNavigation,
    Navbar,
    AgentReferralDashboard,
    SetPassword: ({ state: { submitPassword, goToLogin } }) => (
      <ResetPassword
        title="Set your password"
        goToLogin={goToLogin}
        submitPassword={submitPassword}
        labelText="Your password"
        successMessage="Your password has been successfully set!"
      />
    ),
    ResetPassword: ({ state: { submitPassword, goToLogin } }) => (
      <ResetPassword
        title="Reset your password"
        goToLogin={goToLogin}
        submitPassword={submitPassword}
        labelText="New password"
        successMessage="Your password has been successfully reset!"
      />
    ),
    Login,
    Setup2Fa: ({ state: { verifyViaEmail, verify, tfa, loading } }) => (
      <Setup2faCSS verifyViaEmail={verifyViaEmail} verify={verify} tfa={tfa} loading={loading} />
    ),
    VerifyViaGoogleAuth: ({ state: { verify, resetAuthentication, goToVerifyViaEmail } }) => (
      <VerifyViaGoogleAuth
        goToVerifyViaEmail={goToVerifyViaEmail}
        resetAuthentication={resetAuthentication}
        verify={verify}
      />
    ),
    VerifyViaEmail: ({ state: { username, resetAuthentication, onResendEmail, onGoToVerifyTfa } }) => (
      <VerifyViaEmail
        onGoToVerifyTfa={onGoToVerifyTfa}
        onResendEmail={onResendEmail}
        variant="login"
        resetAuthentication={resetAuthentication}
        username={username}
      />
    ),
    ResetViaEmail: ({ state: { username, goToVerifyViaEmail } }) => {
      return <VerifyViaEmail variant="reset" goToVerifyViaEmail={goToVerifyViaEmail} username={username} />
    },
    SignUp,
    ForgotPassword: ({ state }) => {
      const [loading, setLoading] = useState(false)
      const [email, setEmail] = useState('')
      const { setAlert } = useAlerts()
      const onSubmit = async (data) => {
        setLoading(true)
        state.requestNewPassword(data, (res) => {
          setEmail(data.email)
          setAlert({
            title: res.status,
            message: res.message,
            kind: 'success'
          })
          setLoading(false)
        })
      }

      return (
        <ForgotPassword
          onClickBack={() => state.go('login')}
          onSubmit={onSubmit}
          buttonVariant={loading ? 'primary / loading' : 'primary'}
          email={email}
        />
      )
    },
    DynamicBanner,
    DemoBanner,
    CreateTestAccount,
    HelpSidebar,
    Ping: () => <Ping color="#61A534" style={{ borderRadius: 4 }} />,
    OnboardingTutorialButton,
    ImportEmployees,
    EmployeeNotification,
    EmployeeRoster,
    EmployeeTimesheets,
    CompanySetupControl,
    LeavesPage,
    EmployeeLeave,
    DiscountOfferModal
  }
  if (!merged.hasOwnProperty(componentName)) {
    console.error(`component ${componentName} does not exist`)
    throw new Error()
  }
  const Component = merged[componentName]

  // keep created roots to resolve "container that has already been passed" warning
  // Thease roots also used to unmount the component
  const root = passedRoots.get(element) || createRoot(element)
  passedRoots.set(element, root)

  const queryClient = new QueryClient()

  root.render(
    <ReduxProvider>
      <QueryClientProvider client={queryClient}>
        <AngularContext.Provider value={props}>
          <PDSContextComponent {...props}>
            <FlowbiteProvider>
              <Component {...props} />
            </FlowbiteProvider>
          </PDSContextComponent>
        </AngularContext.Provider>
      </QueryClientProvider>
    </ReduxProvider>
  )
}

createRoot(document.getElementById('reactAlertsPortal')).render(
  <ReduxProvider>
    <Alerts />
  </ReduxProvider>
)

createRoot(document.getElementById('modalGlobalChannel')).render(
  <QueryClientProvider client={new QueryClient()}>
    <ReduxProvider>
      <PayrollerGrowthBookProvider>
        <GlobalModal />
      </PayrollerGrowthBookProvider>
    </ReduxProvider>
  </QueryClientProvider>
)

function ReduxProvider({ children }) {
  return (
    <Provider store={store}>
      <Suspense fallback={<></>}>{children}</Suspense>
    </Provider>
  )
}

function PDSContextComponent({ children, state }) {
  const params = getSearchParams()
  const { setAlert } = useAlerts()
  const { hideModal } = useModal()

  return (
    <PayrollerContextProvider
      value={{ hideModal, setAlert, API_BASE: process.env.API_ENDPOINT, query: params, navigate: state.go }}
    >
      {children}
    </PayrollerContextProvider>
  )
}

createRoot(document.getElementById('react-ga-init')).render(
  <ReduxProvider>
    <PayrollerGrowthBookProvider>
      <GaInit />
    </PayrollerGrowthBookProvider>
  </ReduxProvider>
)
