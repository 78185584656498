import isPrerenderForV2 from './isPrerenderForV2'

// --------PaymentSummary------------
export const url = `${process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE}/v1/subscription/webapp`

export const stripeElementsSrc = !isPrerenderForV2
  ? `${process.env.API_ENDPOINT || process.env.NEXT_PUBLIC_API_BASE}/bindPaymentmethod?from=${
      window.location.protocol
    }//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`
  : ''

export const PERIOD_NAMES = {
  year: 'Yearly',
  month: 'Monthly'
}

export const PROGRESS = {
  CARD_INFO_TOKENIZED: 'CARDS_INFO_TOKENIZED',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

// --------AvailablePlans------------
export const availableFeatures = {
  Lite: [
    {
      available: true,
      featureTitle: 'Timesheets & Rosters'
    },
    {
      available: true,
      featureTitle: 'Leave Management'
    },
    {
      available: true,
      featureTitle: 'Employee App'
    },
    {
      available: true,
      featureTitle: 'Payroll & STP on Mobile'
    },
    {
      available: false,
      featureTitle: 'Payroll & STP on Web'
    },
    {
      available: false,
      featureTitle: 'Super Clearing House'
    },
    {
      available: false,
      featureTitle: 'Xero Integration'
    },
    {
      available: false,
      featureTitle: 'Customised Solution'
    }
  ],
  Standard: [
    {
      available: true,
      featureTitle: 'Timesheets & Rosters'
    },
    {
      available: true,
      featureTitle: 'Leave Management'
    },
    {
      available: true,
      featureTitle: 'Employee App'
    },
    {
      available: true,
      featureTitle: 'Payroll & STP on Mobile'
    },
    {
      available: true,
      featureTitle: 'Payroll & STP on Web'
    },
    {
      available: true,
      featureTitle: 'Super Clearing House'
    },
    {
      available: true,
      featureTitle: 'Xero Integration'
    },
    {
      available: false,
      featureTitle: 'Customised Solution'
    }
  ],
  Enterprise: [
    {
      available: true,
      featureTitle: 'Timesheets & Rosters'
    },
    {
      available: true,
      featureTitle: 'Leave Management'
    },
    {
      available: true,
      featureTitle: 'Employee App'
    },
    {
      available: true,
      featureTitle: 'Payroll & STP on Mobile'
    },
    {
      available: true,
      featureTitle: 'Payroll & STP on Web'
    },
    {
      available: true,
      featureTitle: 'Super Clearing House'
    },
    {
      available: true,
      featureTitle: 'Xero Integration'
    },
    {
      available: true,
      featureTitle: 'Customised Solution'
    }
  ]
}

// --------http------------
export const CATEGORY = {
  WEBAPP: 'webapp',
  TIMESHEET: 'timesheet'
}
