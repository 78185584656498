import React, { useRef, useEffect } from 'react'
import { usePaywall } from './usePaywall'
import { AvailablePlans } from './AvailablePlans'
import { PaymentSummary } from './PaymentSummary'
import { FAQ } from './FAQ'
import { useIntersection, useModal } from '../../../../hooks'
import { PeriodButton } from './comps/PeriodButton'
import { STEPS } from '../../../../../reducers/paywallSlice'
import { StepButton } from './comps/StepButton'
import { GaEvent } from '../../../../components/GaEvent'
import * as s from './styles'
import { CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '../../../../../api/company'
import { setCompany } from '../../../../../reducers/appSlice'
import { logEvent } from '../../../../../modules/analytics'
import moment from 'moment'

const PAY_WALL_SEEN_DATE = moment('2024-03-01').endOf('day').toISOString() // track payWallSeen after 2024-03-01

export function Paywall({ isV2, onSuccessPayment, modalCloseTrigger }) {
  const { step, seats, currentPrice, isTrialPlan, payWallSeen, createdAt, setStep, setPeriod, isLoading } = usePaywall()
  const { hideModal } = useModal()

  const paywallRef = useRef(null)
  const { gbFeatures } = useSelector((state) => ({
    gbFeatures: state.app.gbFeatures
  }))
  const [faqRef, faqVisible] = useIntersection({ remainAfterPassed: true })
  const { highlight30dMoneyBack } = gbFeatures?.paywall_no_lite || {}

  useEffect(() => {
    logEvent('Triggered Paywall')
  }, [])

  const [updateCompany] = useMutation()
  const dispatch = useDispatch()

  const updatePayWallSeen = async () => {
    try {
      let res = await updateCompany({ company: { payWallSeen: new Date() } })
      dispatch(setCompany(res.data.company))
    } catch (err) {
      logEvent('Failed to update payWallSeen attribute', err)
    }
  }

  // Should only check once if paywall modal is visible
  useEffect(() => {
    if (moment(createdAt).isAfter(PAY_WALL_SEEN_DATE)) {
      updatePayWallSeen()
    }
  }, [])

  return (
    <div className={isV2 ? s.v2PaywallContainer : s.paywallContainer}>
      <div className={s.stepWrapper} ref={paywallRef}>
        <StepButton stepOrder={0} />
        <div className={s.stepDivider} />
        <StepButton stepOrder={1} />
      </div>
      <div
        className={s.btnClose}
        onClick={() => {
          if (isV2) {
            modalCloseTrigger()
          } else {
            hideModal('Paywall')
          }
          // init paywall state
          setStep(0)
          setPeriod('year')
        }}
      >
        <img width={'18px'} height={'18px'} src={isV2 ? '/static/images/close.svg' : '/img/paywall/close.svg'} />
      </div>

      <h2 className={s.title}>{STEPS[step]}</h2>

      <div className={s.periodWrapper}>
        <p className={s.description}>Cancel anytime</p>
        <div className={s.periodContainer}>
          <PeriodButton name="year" />
          <PeriodButton name="month" />
        </div>
      </div>

      {!!highlight30dMoneyBack && (
        <div
          className={`flex items-center w-fit px-3 py-0.5 mx-auto !mb-[27px] !font-inter gap-x-2${
            isV2 ? ' flex-col' : ' flex-column'
          }`}
        >
          <div className="uppercase !font-semibold !text-2xl !text-black">
            JUST FOR YOU, 30 DAY MONEY BACK GUARANTEE
          </div>
          <p className="!text-gray-400 !text-base ">Only available for first time subscribers</p>
        </div>
      )}

      {isLoading && <CircularProgress className={s.loading} />}
      {!isLoading && (
        <>
          {step === 0 && <AvailablePlans isV2={isV2} />}
          {step === 1 && <PaymentSummary isV2={isV2} onSuccessPayment={onSuccessPayment} />}
        </>
      )}

      <div className={s.googleReview}>
        <img
          alt="Google My Business Star Rating (4.9 / Based on 2,742 reviews)"
          src={isV2 ? '/static/images/google.svg' : '/img/paywall/google.svg'}
        />
      </div>

      <FAQ ref={faqRef} isV2={isV2} />
      <div className={s.sticky}>
        <button
          onClick={() => {
            const scrollTarget = faqVisible ? paywallRef.current : faqRef.current
            scrollTarget?.scrollIntoView({ behavior: 'smooth', block: faqVisible ? 'start' : 'center' })
          }}
          className={s.floatingButton}
        >
          <img
            src={isV2 ? '/static/images/floating-arrow.svg' : 'img/paywall/floating-arrow.svg'}
            className={s.floatingIcon(faqVisible)}
          />
          {faqVisible ? 'TO TOP' : 'FAQ'}
        </button>
      </div>

      <GaEvent
        name="view_item"
        items={[
          {
            item_id: isTrialPlan ? 'free_trial' : 'subscription',
            item_name: isTrialPlan ? 'Free Trial' : 'Subscription',
            item_category: 'Annual',
            coupon: 'VARIANTC',
            price: currentPrice,
            currency: 'AUD',
            quantity: seats
          }
        ]}
        description="Paywall - view_item"
      />
    </div>
  )
}
