/* Common */
export const title = "!font-['Rubik'] !text-[24px] !leading-[28px] !font-medium"
export const halfItem = 'flex-1'
export const divider = '!border-[0.5px] !border-solid w-full !border-cardBorder !mt-5 !mb-5'

/* Order Summary */
export const description = '!font-normal !text-[14px] !leading-[17px]'
export const orderItemContainer = 'flex justify-between'
export const orderItemTitle = '!font-semibold !text-[16px] !leading-[21px]'
export const orderItemDescription = '!font-normal !text-[16px]'
export const orderPlanContainer = 'flex flex-col !gap-[18px] !mt-[26px]'
export const detailTextMedium = '!font-medium !text-[16px] !leading-[19px]'
export const detailTextNormal = '!font-normal !text-[16px] !leading-[19px]'
export const rolloverWarn = '!text-[#999999] !text-[14px] !mt-[14px] !italic'
export const inputSeat =
  'border-solid-non-color !border-periodBorder rounded-[4px] !w-[8vw] !h-[30px] !text-right pr-[15px]'
export const v2InputSeat =
  'border border-solid-non-color !border-periodBorder rounded-[4px] !w-[8vw] !h-[30px] !text-right pr-[15px]'
export const orderPriceContainer = 'flex flex-col !gap-[21px]'
export const orderDescriptionContainer = 'flex flex-col !gap-[21px]'

/* Payment Details */
export const btnConfirm =
  '!cursor-pointer !bg-primary w-full !rounded-[4px] text-white !py-[14px] !border-transparent !text-[16px] !leading-[19px] !font-medium'

/* Stripe Elements */
export const loadingContainer = 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
export const stripContainer = 'ring ring-transparent !min-h-[282px] !w-full form-stripe-full'
