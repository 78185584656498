import { setInitialState, setModals } from '../reducers/appSlice'
import { ngPostData } from './httpService'
import { setAnchorElPopover, setOpenModalInviteYourEmployee } from '/src/reducers/subscriptionSlice'
import { updateSubscription } from '/src/scheduling/react-subscription/utils/share'
import { cloneDeep } from 'lodash'

angular
  .module('starter.services')
  .factory(
    'employeeModalService',
    function (
      $rootScope,
      Service,
      Http,
      EmployeeAction,
      LeaveAction,
      $filter,
      $window,
      $timeout,
      Analytics,
      GLOBALS,
      $ngRedux,
      Http2
    ) {
      const r2 = Service.r2
      const r5 = Service.r5

      function newEmployee() {
        Analytics.logEvent('Press Add Employee')

        Service.modal(
          'newEmployeeModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              // true - add manually detail and other ; false - click invite
              $scope.isAddDetails = false
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }
              $scope.currentSuperRate = Service.financialYearToSuperRate()

              $scope.currentIndex = 0

              $scope.selectedItem = null

              $scope.local = {}
              $scope.local.casualLoadingPercent = 0
              $scope.local.leaveLoading = 0
              $scope.PayrollAndRoster = false
              $ngRedux.connect((state) => {
                const activeEmployees = state.app.employees.filter(
                  (e) => (e.s === 'a' || e.s === 'p') && e.rosterOnly !== true
                )
                const spotIsFull = !(
                  $scope?.reactState?.rosterOnly ||
                  activeEmployees.length < state.app.subscriptionStatus.webapp.remainingSeats
                )
                return {
                  activeEmployees,
                  spotIsFull,
                  subscriptionStatus: state.app.subscriptionStatus.webapp,
                  isTrialOnly: state.subscription.isTrialOnly,
                  isSubValid: state.subscription.isSubscribed
                }
              })($scope)

              // change date of birth
              $scope.changeDD = () => {
                const inputMM = document.querySelector('#inputMM')
                if (
                  $scope.employeeDetails_new.dob_dd?.length === 2 &&
                  (!$scope.employeeDetails_new.dob_mm || $scope.employeeDetails_new.dob_mm?.length !== 2)
                ) {
                  inputMM.focus()
                }
              }

              $scope.changeMM = () => {
                const inputYYYY = document.querySelector('#inputYYYY')
                if (
                  $scope.employeeDetails_new.dob_mm?.length === 2 &&
                  (!$scope.employeeDetails_new.dob_yyyy || $scope.employeeDetails_new.dob_yyyy?.length !== 4)
                ) {
                  inputYYYY.focus()
                }
              }

              // change start date
              $scope.changeStartDateDay = () => {
                const inputStartDateMonth = document.querySelector('#startDateMonth')
                if (
                  $scope.employeeDetails_new.sd_dd?.length === 2 &&
                  (!$scope.employeeDetails_new.sd_mm || $scope.employeeDetails_new.sd_mm?.length !== 2)
                ) {
                  inputStartDateMonth.focus()
                }
              }

              $scope.changeStartDateMonth = () => {
                const inputStartDateYear = document.querySelector('#startDateYear')
                if (
                  $scope.employeeDetails_new.sd_mm?.length === 2 &&
                  (!$scope.employeeDetails_new.sd_yyyy || $scope.employeeDetails_new.sd_yyyy?.length !== 4)
                ) {
                  inputStartDateYear.focus()
                }
              }

              $scope.changeAcceptAddSubscription = () => {
                if ($scope.employeeDetails_new.accept && $scope.spotIsFull) {
                  $scope.employeeDetailsError.AcceptAddSubscription = false
                }
              }

              $scope.changeTypeAppear = (value) => {
                $scope.PayrollAndRoster = value
                $scope.employeeDetails_new.rosterOnly = value
                // $scope.employeeDetails_new = { ...$scope.employeeDetails_new, rosterOnly: value }
                $scope.reactState.rosterOnly = value
              }
              $scope.onAceptProceed = () => {
                $scope.reactState = {
                  ...$scope.reactState,
                  aceptProceedInviteEmployee: true
                }
                $scope.next_newEmployee()
              }
              $scope.reactState = {
                onAceptProceed: $scope.onAceptProceed,
                aceptProceedInviteEmployee: false
              }
              $scope.employeeDetails_new = {
                iv: false,
                pm: 'b',
                ty: 'f',
                pu: 'h',
                wwh: ($rootScope.company.wh || 7.6) * 5,
                su: {
                  ty: 'r',
                  sm: {},
                  sendSuperannuation: $rootScope.gbFeatures.sendsuperform
                },
                tx: {
                  rs: 'a',
                  o_ft: true,
                  ...($scope.company.stp2020 && {
                    incomeStreamType: 'SAW',
                    homeCountry: 'GB'
                  })
                },
                ll: 0,
                tfnd: {}
              }

              $scope.employeeDetails4_edit = {
                tx: {
                  rs: 'a',
                  o_ft: true,
                  ...($scope.company.stp2020 && {
                    incomeStreamType: 'SAW',
                    homeCountry: 'GB'
                  })
                },
                tfnd: {}
              }
              if (GLOBALS.GUI_DEBUG) {
                $scope.employeeDetails4_edit = {
                  dob_dd: 1,
                  dob_mm: 1,
                  dob_yyyy: 2000,
                  tx: {
                    rs: 'a',
                    o_ft: true,
                    o_studentLoans: false,
                    o_shearer: false,
                    o_whmRegistered: false,
                    no: _.sample([
                      '151994688',
                      '151994243',
                      '151994661',
                      '151994716',
                      '151994349',
                      '151994322',
                      '151994251',
                      '151994330',
                      '151994373',
                      '151994401',
                      '151994531',
                      '151994294',
                      '151994444',
                      '151994811',
                      '151994838',
                      '151994854',
                      '151994870',
                      '151994897',
                      '151994917',
                      '151995058',
                      '151995074',
                      '151995090',
                      '151995110',
                      '151995137',
                      '151995216',
                      '151995232',
                      '151995259',
                      '151995275',
                      '151995291',
                      '151995338'
                    ]),
                    // homeCountry: 'GB', // _.sample([null, 'GB']),
                    // txe: _.sample([null, 'no', 'tp', '18', 'ps']),
                    txe: null
                    // incomeStreamType: 'SAW'
                  },
                  tfnd: {}
                }
                $scope.employeeDetails_new = {
                  ...$scope.employeeDetails_new,
                  sd_dd: '1',
                  sd_mm: '1',
                  sd_yyyy: '2021',
                  f: `FirstName${_.random(100)}`,
                  l: `LastName${_.random(100)}`,
                  t: 'Fake Employee',
                  e: `${GLOBALS.TESTMAIL_PFX}.emp.${_.random(100)}@inbox.testmail.app`,
                  a_1: `StreetAddress${_.random(100)}`,
                  a_2: 'Fake Employee',
                  a_c: `City${_.random(100)}`,
                  a_p: '2000',
                  a_s: 'NSW',
                  ph: `PhoneNumber${_.random(100)}`,
                  pr: 50,
                  b_n: 'Westpac',
                  b_b: '732000',
                  b_no: '123456789'
                }
                // Http(
                //   {
                //     method: 'get',
                //     api: window.location.origin + '/fakename',
                //     disableErrorMsg: true
                //   },
                //   (res) => {
                //     let name = res
                //     $scope.employeeDetails_new.f = res.f
                //     $scope.employeeDetails_new.l = res.l
                //     $scope.employeeDetails_new.e = `${res.f}.${res.l}@test.bookipi.com`.toLowerCase()
                //   },
                //   (err) => {}
                // )
              }

              $scope.leaveLoadingInfo = function () {
                leaveLoadingInfo()
              }
              $scope.incomeTypeInfo = incomeTypeInfo

              $scope.getSuperList = (search) => {
                return new Promise((resolve, reject) => {
                  let superList = []
                  if (search === '') {
                    return resolve(superList)
                  }
                  Http(
                    {
                      method: 'get',
                      api: '/super/search/' + search,
                      forceSend: true
                    },
                    (res) => {
                      $scope.superSearching = false
                      for (const key in res) {
                        if (!isNaN(key) && res.hasOwnProperty(key)) {
                          const element = res[key]
                          superList.push(element)
                        }
                      }
                      return resolve(superList)
                      // 저장된 employee data 를 update 한다.
                    },
                    (err) => {
                      console.log(err)
                      $scope.superSearching = false
                      return reject(err)
                    }
                  )
                })
              }

              $scope.searchSuper = function (superValue) {
                if (!superValue) {
                  $scope.employeeDetailsError.superFundName = true
                  return false
                } else {
                  $scope.employeeDetailsError.superFundName = false
                }
                // $scope.loading = true;
                $scope.superSearching = true
                $scope.getSuperList(superValue).then((superList) => {
                  $scope.superList = superList
                })
              }

              // Google map api place autocomplete
              // variables
              $scope.autocompleteService = new google.maps.places.AutocompleteService()
              $scope.addressSuggestions = []
              $scope.showSuggests = false
              $scope.hoverOptionIndex = -1
              document.addEventListener('click', $scope.onBlurAddress1)
              let address1Input = document.getElementById('address1')
              const geocoder = new google.maps.Geocoder()

              // callback get place gg map api
              $scope.autocompleteCallback = function (predictions = []) {
                if (predictions && predictions.length > 0) {
                  $scope.showSuggests = true
                  $scope.addressSuggestions = predictions
                } else {
                  $scope.showSuggests = false
                  $scope.addressSuggestions = []
                }
                $scope.$apply()
              }

              // event text change address1 input
              $scope.changeAddress1 = function () {
                $scope.hoverOptionIndex = -1
                if ($scope.employeeDetails_new.a_1 === '') {
                  $scope.autocompleteCallback()
                  return
                }

                if ($scope.autocompleteService) {
                  $scope.autocompleteService.getPlacePredictions(
                    {
                      types: ['geocode'],
                      componentRestrictions: { country: 'au' },
                      input: $scope.employeeDetails_new.a_1
                    },
                    $scope.autocompleteCallback
                  )
                }
              }

              // event keydown address1 input
              $scope.onKeyDownAddress1 = function (event) {
                if ($scope.addressSuggestions?.length === 0) return
                switch (event.key) {
                  case 'Enter':
                    if ($scope.hoverOptionIndex === -1 || $scope.addressSuggestions?.length === 0) return
                    $scope.selectAddress1($scope.addressSuggestions[$scope.hoverOptionIndex], $scope.hoverOptionIndex)
                    break
                  case 'ArrowUp':
                    event.preventDefault()
                    if ($scope.hoverOptionIndex === 0) {
                      $scope.hoverOptionIndex = $scope.addressSuggestions.length - 1
                    } else {
                      $scope.hoverOptionIndex -= 1
                    }
                    break
                  case 'ArrowDown':
                    event.preventDefault()
                    if ($scope.hoverOptionIndex === $scope.addressSuggestions.length - 1) {
                      $scope.hoverOptionIndex = 0
                    } else {
                      $scope.hoverOptionIndex += 1
                    }
                    break
                }
              }

              // hover place suggest option
              $scope.hoverOption = function (hoveredValue, hoveredIndex) {
                $scope.hoverOptionIndex = hoveredIndex
              }

              // blur address1 input
              $scope.onBlurAddress1 = function (event) {
                if (!$scope.showSuggests) return
                let isSelect = event.target.closest('#suggestionContainer')
                let isAddress1Input = event.target.id === 'address1'
                if (!isSelect && !isAddress1Input) {
                  $scope.hoverOptionIndex = -1
                  $scope.showSuggests = false
                  $scope.$apply()
                }
              }

              // event click + select suggest option
              $scope.selectAddress1 = function (selectedValue, selectedIndex) {
                geocoder
                  .geocode({ placeId: selectedValue?.place_id || '' })
                  .then(({ results }) => {
                    $scope.hoverOptionIndex = -1
                    $scope.showSuggests = false
                    $scope.$apply()
                    const address_components = results?.[0]?.address_components
                    let street = ''
                    let numberStreet = ''

                    for (const component of address_components) {
                      const componentType = component.types[0]

                      switch (componentType) {
                        case 'route': {
                          street = component.long_name
                          break
                        }
                        case 'street_number': {
                          numberStreet = component.long_name
                          break
                        }
                        case 'postal_code': {
                          $scope.employeeDetails_new.a_p = component.long_name
                          break
                        }
                        case 'locality': {
                          $scope.employeeDetails_new.a_c = component.long_name
                          break
                        }
                        case 'administrative_area_level_1': {
                          $scope.employeeDetails_new.a_s = component.short_name
                          break
                        }
                      }
                    }
                    $scope.employeeDetails_new.a_1 = numberStreet ? `${numberStreet} ${street}` : street
                    $scope.employeeDetails_new.a_2 = ''
                  })
                  .catch((e) => {
                    console.log('Google map api error!')
                  })
                  .finally(() => {
                    if (address1Input) {
                      address1Input.blur()
                    }
                    $scope.hoverOptionIndex = -1
                    $scope.showSuggests = false
                    $scope.$apply()
                  })
              }

              $scope.previous_newEmployee = function () {
                if ($scope.employeeDetails_new.iv && $scope.currentIndex == 4) {
                  $scope.currentIndex = 0
                } else {
                  $scope.currentIndex = $scope.currentIndex - 1
                }
              }

              $scope.textChange = function (newText) {
                $scope.superSearchValue = newText
              }

              $scope.selectSuper = function (superFundDetails) {
                $scope.employeeDetails_new.su.usi = superFundDetails.uniqueSuperannuationIdentifier
                $scope.employeeDetails_new.su.n = superFundDetails.productName
                $scope.employeeDetails_new.su.abn = superFundDetails.australianBusinessNumber
                $scope.createSuperError = ''
                $scope.superList = []
              }

              $scope.residencyChanged = function () {
                $scope.employeeDetails4_edit.tx.o_ft = false
                $scope.employeeDetails4_edit.tx.o_studentLoans = false
                $scope.employeeDetails4_edit.tx.o_shearer = false
              }

              $scope.local.casualLoadingPercent = ($rootScope.company.cl || 0.25) * 100
              $scope.employeeDetailsError = {}
              // $scope.maxDate = new Date("2100-11-11");

              function validateEmployeeDetail1(callback) {
                var error = false
                $scope.employeeDetailsError = {}

                if (!$scope.employeeDetails_new.f) {
                  $scope.employeeDetailsError.firstname = true
                  error = true
                }
                if (!$scope.employeeDetails_new.l) {
                  $scope.employeeDetailsError.lastname = true
                  error = true
                }
                if (!$scope.employeeDetails_new.t) {
                  $scope.employeeDetailsError.jobTitle = true
                  error = true
                }
                if (!$scope.employeeDetails_new.e) {
                  $scope.employeeDetailsError.email = true
                  error = true
                }

                if (
                  !$scope.employeeDetails_new.sd_dd ||
                  $scope.employeeDetails_new.sd_dd < 1 ||
                  $scope.employeeDetails_new.sd_dd > 31
                ) {
                  $scope.employeeDetailsError.sd_dd = true
                  error = true
                }

                if (
                  !$scope.employeeDetails_new.sd_mm ||
                  $scope.employeeDetails_new.sd_mm < 1 ||
                  $scope.employeeDetails_new.sd_mm > 12
                ) {
                  $scope.employeeDetailsError.sd_mm = true
                  error = true
                }

                if (
                  !$scope.employeeDetails_new.sd_yyyy ||
                  $scope.employeeDetails_new.sd_yyyy < 1950 ||
                  $scope.employeeDetails_new.sd_yyyy > 2030
                ) {
                  if ($scope.employeeDetails_new.sd_yyyy < 1950) {
                    $scope.employeeDetailsError.startDate =
                      'Employment Start Date must be later than the 1st of January 1950'
                  }
                  $scope.employeeDetailsError.sd_yyyy = true
                  error = true
                }

                if (error) {
                  return false
                } else {
                  callback()
                }
              }

              $scope.addDetails = function () {
                $scope.isAddDetails = true
                validateEmployeeDetail1(() => {
                  $scope.employeeDetails_new.iv = false
                  $scope.currentIndex++
                })
                document.addEventListener('click', $scope.onBlurAddress1)
                setTimeout(() => {
                  address1Input = document.getElementById('address1')
                }, 1000)
              }

              $scope.getHourlyRate = function () {
                return getHourlyRate(
                  $scope.employeeDetails_new.ty,
                  $scope.employeeDetails_new.wwh,
                  $scope.employeeDetails_new.pr,
                  $scope.employeeDetails_new.pu,
                  $scope.local.casualLoadingPercent
                )
              }

              $scope.TFNCheck = function (field) {
                if (Service.TFNCheck(field || $scope.employeeDetails_new.tx.no)) {
                  $scope.employeeDetailsError.tfn = false
                  return 'valid'
                } else {
                  $scope.employeeDetailsError.tfn = true
                  return 'invalid'
                }
              }
              $scope.next_newEmployee = function () {
                let error = false

                switch ($scope.currentIndex) {
                  case 0:
                    $scope.isAddDetails = false
                    if (
                      !$scope.reactState.aceptProceedInviteEmployee &&
                      !$rootScope.allowTrial &&
                      $scope.subscriptionStatus.isValid
                    ) {
                      $ngRedux.dispatch(setOpenModalInviteYourEmployee(true))
                      return
                    }
                    $scope.reactState = {
                      ...$scope.reactState,
                      onAceptProceed: $scope.onAceptProceed,
                      aceptProceedInviteEmployee: false
                    }
                    // validate fields
                    validateEmployeeDetail1(() => {
                      $scope.employeeDetails_new.iv = true
                      // $scope.slickConfig.method.slickGoTo(3);
                      $scope.currentIndex = 4
                    })

                    // send the superannuation only when "Add employee's details manually" (case 1:)
                    if ($rootScope.gbFeatures.sendsuperform) {
                      $scope.employeeDetails_new.su.sendSuperannuation = false
                    }

                    break
                  case 1:
                    // send invitation
                    if (!$scope.employeeDetails_new.a_1) {
                      $scope.employeeDetailsError.address1 = true
                      error = true
                    }
                    if (!$scope.employeeDetails_new.a_c) {
                      $scope.employeeDetailsError.suburb = true
                      error = true
                    }
                    if (!$scope.employeeDetails_new.a_p) {
                      $scope.employeeDetailsError.postcode = true
                      error = true
                    }
                    if (!$scope.employeeDetails_new.a_s) {
                      $scope.employeeDetailsError.state = true
                      error = true
                    }
                    // if(!$scope.employeeDetails_new.ph){
                    //    $scope.employeeDetailsError.phone= true;
                    //    error= true;
                    // }

                    if (error) {
                      return false
                    }
                    $scope.currentIndex++
                    // $scope.slickConfig.method.slickNext();
                    break
                  case 2:
                    // send invitation
                    $scope.employeeDetailsError = {}
                    if ($scope.employeeDetails_new.pm == 'b') {
                      if (!$scope.employeeDetails_new.b_n) {
                        $scope.employeeDetailsError.accountName = true
                        error = true
                      }

                      if (!$scope.employeeDetails_new.b_b || !/^[0-9]{6}$/i.test($scope.employeeDetails_new.b_b)) {
                        $scope.employeeDetailsError.bsb = true
                        error = true
                      }

                      if (!$scope.employeeDetails_new.b_no || !/^[0-9-]{1,9}$/i.test($scope.employeeDetails_new.b_no)) {
                        $scope.employeeDetailsError.accountNumber = true
                        error = true
                      }
                    }

                    if ($scope.employeeDetails_new.su.ty == 's') {
                      if (!$scope.employeeDetails_new.su.sm.n) {
                        $scope.employeeDetailsError.selfManagedFundName = true
                        error = true
                      }

                      if (
                        !$scope.employeeDetails_new.su.sm.abn ||
                        !Service.isABN($scope.employeeDetails_new.su.sm.abn)
                      ) {
                        $scope.employeeDetailsError.selfManagedFundAbn = true
                        error = true
                      }

                      if (!$scope.employeeDetails_new.su.sm.b_n) {
                        $scope.employeeDetailsError.selfSuperAccountName = true
                        error = true
                      }

                      if (
                        !$scope.employeeDetails_new.su.sm.b_b ||
                        !/^[0-9]{6}$/i.test($scope.employeeDetails_new.su.sm.b_b)
                      ) {
                        $scope.employeeDetailsError.selfSuperBsb = true
                        error = true
                      }

                      if (
                        !$scope.employeeDetails_new.su.sm.b_no ||
                        !/^[0-9-]{1,9}$/i.test($scope.employeeDetails_new.su.sm.b_no)
                      ) {
                        $scope.employeeDetailsError.selfSuperAccountNumber = true
                        error = true
                      }
                    }

                    if (error) {
                      return false
                    }

                    // $scope.slickConfig.method.slickNext();
                    $scope.currentIndex++
                    break
                  case 3:
                    // Old version had copied code split into separate controllers
                    // Except the controllers used difference scope names. Using a common
                    // scope name for refactor but to still be compatible with design
                    $scope.employeeDetailsError4 = {}

                    if (
                      ($scope.employeeDetails4_edit.dob_dd &&
                        ($scope.employeeDetails4_edit.dob_dd < 1 || $scope.employeeDetails4_edit.dob_dd > 31)) ||
                      (($scope.employeeDetails4_edit.dob_mm ||
                        $scope.employeeDetails4_edit.dob_yyyy ||
                        ($scope.employeeDetails4_edit.tfnd && $scope.employeeDetails4_edit.tfnd.p)) &&
                        !$scope.employeeDetails4_edit.dob_dd)
                    ) {
                      $scope.employeeDetailsError4.dob_dd = true
                      error = true
                    }

                    if (
                      ($scope.employeeDetails4_edit.dob_mm &&
                        ($scope.employeeDetails4_edit.dob_mm < 1 || $scope.employeeDetails4_edit.dob_mm > 12)) ||
                      (($scope.employeeDetails4_edit.dob_dd ||
                        $scope.employeeDetails4_edit.dob_yyyy ||
                        ($scope.employeeDetails4_edit.tfnd && $scope.employeeDetails4_edit.tfnd.p)) &&
                        !$scope.employeeDetails4_edit.dob_mm)
                    ) {
                      $scope.employeeDetailsError4.dob_mm = true
                      error = true
                    }

                    if (
                      ($scope.employeeDetails4_edit.dob_yyyy &&
                        ($scope.employeeDetails4_edit.dob_yyyy < 1900 ||
                          $scope.employeeDetails4_edit.dob_yyyy > 2018)) ||
                      (($scope.employeeDetails4_edit.dob_mm ||
                        $scope.employeeDetails4_edit.dob_dd ||
                        ($scope.employeeDetails4_edit.tfnd && $scope.employeeDetails4_edit.tfnd.p)) &&
                        !$scope.employeeDetails4_edit.dob_yyyy)
                    ) {
                      $scope.employeeDetailsError4.dob_yyyy = true
                      error = true
                    }

                    if (
                      (!$scope.employeeDetails4_edit.tx || !$scope.employeeDetails4_edit.tx.txe) &&
                      !Service.TFNCheck($scope.employeeDetails4_edit.tx.no)
                      // && !GLOBALS.GUI_DEBUG
                    ) {
                      $scope.employeeDetailsError4.tfn = true
                      error = true
                    }

                    if ($scope.employeeDetails4_edit.tfnd.p) {
                      $scope.employeeDetails4_edit.tfnd.s_at = Service.getUTCDate()
                    }

                    if (error) {
                      return false
                    }

                    $scope.employeeDetails_new = {
                      ...$scope.employeeDetails_new,
                      ...$scope.employeeDetails4_edit
                    }

                    $scope.currentIndex++

                    break

                  case 4:
                    $scope.employeeDetailsError = {}
                    if ($scope.employeeDetails_new.wwh === null || isNaN($scope.employeeDetails_new.wwh)) {
                      $scope.employeeDetailsError.weeklyWorkHours = true
                      error = true
                    }

                    if ($scope.employeeDetails_new.pr === null || isNaN($scope.employeeDetails_new.pr)) {
                      $scope.employeeDetailsError.payRate = true
                      error = true
                    }

                    if (
                      $scope.subscriptionStatus.isValid &&
                      !$scope.employeeDetails_new.accept &&
                      $scope.spotIsFull &&
                      !$scope.PayrollAndRoster
                    ) {
                      $scope.employeeDetailsError.AcceptAddSubscription = true
                      error = true
                    }
                    if (
                      $scope.company.stp2020 &&
                      $scope.employeeDetails_new.iv &&
                      !$scope.employeeDetails_new.tx.incomeStreamType
                    ) {
                      $scope.employeeDetailsError.stpIncomeStreamType = true
                      error = true
                    }
                    if (error) {
                      return false
                    }

                    $scope.loading = true

                    switch ($scope.employeeDetails_new.tx.txe) {
                      case 'no':
                        $scope.employeeDetails_new.tx.no = '000000000'
                        break
                      case 'tp':
                        $scope.employeeDetails_new.tx.no = '111111111'
                        break
                      case '18':
                        $scope.employeeDetails_new.tx.no = '333333333'
                        break
                      case 'ps':
                        $scope.employeeDetails_new.tx.no = '444444444'
                        break
                      default:
                        break
                    }
                    // calculate hourly rate
                    $scope.employeeDetails_new.r = getHourlyRate(
                      $scope.employeeDetails_new.ty,
                      $scope.employeeDetails_new.wwh,
                      $scope.employeeDetails_new.pr,
                      $scope.employeeDetails_new.pu,
                      $scope.local.casualLoadingPercent
                    )

                    $scope.employeeDetails_new.cl =
                      $scope.local.casualLoadingPercent == null
                        ? $rootScope.company.cl
                        : $scope.local.casualLoadingPercent / 100
                    // $scope.employeeDetails_new.cl = $rootScope.company.cl;

                    $scope.employeeDetails_new.ll =
                      $scope.local.leaveLoading && $scope.local.leaveLoading > 0 ? $scope.local.leaveLoading / 100 : 0
                    if (
                      $scope.subscriptionStatus.code !== 'SUBSCRIPTION_NO_SUB' && // don't check unless there is a sub
                      $scope.employeeDetails_new.accept &&
                      $scope.spotIsFull
                    ) {
                      $ngRedux
                        .dispatch(
                          updateSubscription({
                            HttpPromise: Http2,
                            plan: $scope.subscriptionStatus.period,
                            seats:
                              $ngRedux.getState().app.subscriptionStatus.webapp.seats -
                              $ngRedux.getState().app.subscriptionStatus.webapp.remainingSeats +
                              $scope.activeEmployees.length +
                              1,
                            method: 'Inviting employee(Employee Page)'
                          })
                        )
                        .unwrap()
                        .then(() => {
                          Http(
                            {
                              method: 'post',
                              api: '/v1/employee/',
                              data: {
                                companyId: $rootScope.company._id,
                                employee: $scope.employeeDetails_new
                              },
                              disableErrorMsg: false
                            },
                            async (res) => {
                              await ngPostData({
                                api: '/v1/invite-employee-app',
                                data: { employeeIds: [res.employee._id] }
                              })
                              $scope.loading = false
                              Service.alert('Success', 'Employee Added')

                              // 저장된 employee data 를 rootScope 에 더한다.
                              EmployeeAction.add(res.employee)
                              $scope.cancel()
                              Analytics.logEvent('Employee Added', {
                                sendSuperannuation: $scope.employeeDetails_new.su.sendSuperannuation
                              })
                              if (res.employee.iv) {
                                Analytics.logEvent('Invitation Emails Sent Count')
                              }
                            },
                            (err) => {
                              if (err.message) {
                                Service.alert(err.err, err.message, 'error')
                              }
                              $scope.loading = false
                            }
                          )
                        })
                        .finally(() => ($scope.loading = false))
                    } else {
                      Http(
                        {
                          method: 'post',
                          api: '/v1/employee/',
                          data: {
                            companyId: $rootScope.company._id,
                            employee: $scope.employeeDetails_new
                          },
                          disableErrorMsg: false
                        },
                        async (res) => {
                          await ngPostData({
                            api: '/v1/invite-employee-app',
                            data: { employeeIds: [res.employee._id] }
                          })
                          $scope.loading = false
                          Service.alert('Success', 'Employee Added')

                          // 저장된 employee data 를 rootScope 에 더한다.
                          EmployeeAction.add(res.employee)
                          $scope.cancel()
                          Analytics.logEvent('Employee Added', {
                            sendSuperannuation: $scope.employeeDetails_new.su.sendSuperannuation
                          })
                          if (res.employee.iv) {
                            Analytics.logEvent('Invitation Emails Sent Count')
                          }
                        },
                        (err) => {
                          if (err.message) {
                            Service.alert(err.err, err.message, 'error')
                          }
                          $scope.loading = false
                        }
                      )
                    }

                    if ($rootScope.payRuns.length === 0) {
                      // open the popup after 1s delay
                      $timeout(() => {
                        $ngRedux.dispatch(setModals([{ kind: 'CheckList2', channel: 'local' }]))
                      }, 1000)
                    }

                    break
                  default:
                    break
                }
              }
              $scope.hourlyRateInfo = function () {
                hourlyRateInfo()
              }
            }
          ],
          { size: 'xxl' }
        )
      }

      function editEmployee(employeeDetailsOrigin, currentTab, successCallback, errorCallback) {
        employeeDetailsOrigin = Service.sanitizeObj(angular.copy(employeeDetailsOrigin))
        Service.modal(
          'editEmployeeModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.appState = {}
              $scope.isSuperSalaryPercentage = false
              $ngRedux.connect((state) => state.app)($scope.appState)

              $scope.currentSuperRate = Service.financialYearToSuperRate()

              $scope.onToggleSuperSalaryPercentage = () => {
                $scope.isSuperSalaryPercentage = !$scope.isSuperSalaryPercentage
              }

              // super type 이 없는경우 넣어준다.
              $scope.openPopover = (e) => $ngRedux.dispatch(setAnchorElPopover(e.currentTarget))
              if (employeeDetailsOrigin.su && !employeeDetailsOrigin.su.ty) {
                employeeDetailsOrigin.su.ty = 'r'
                employeeDetailsOrigin.su.sm = {}
              }
              $scope.employeeDetailsOrigin = employeeDetailsOrigin

              function initialize() {
                $scope.stp2020ready = $scope.company.stp2020 && $scope.employeeDetailsOrigin.tx?.stp2020
                $scope.employeeDetails1_edit = {
                  f: $scope.employeeDetailsOrigin.f || null,
                  l: $scope.employeeDetailsOrigin.l || null,
                  sx: $scope.employeeDetailsOrigin.sx || null,
                  e: $scope.employeeDetailsOrigin.e || null,
                  ph: $scope.employeeDetailsOrigin.ph || null,
                  a_1: $scope.employeeDetailsOrigin.a_1 || null,
                  a_2: $scope.employeeDetailsOrigin.a_2 || null,
                  a_c: $scope.employeeDetailsOrigin.a_c || null,
                  a_p: $scope.employeeDetailsOrigin.a_p || null,
                  a_s: $scope.employeeDetailsOrigin.a_s || null
                }

                $scope.employeeDetails2_edit = {
                  pm: $scope.employeeDetailsOrigin.pm || null,
                  b_n: $scope.employeeDetailsOrigin.b_n || null,
                  b_b: $scope.employeeDetailsOrigin.b_b || null,
                  b_no: $scope.employeeDetailsOrigin.b_no || null,
                  su: $scope.employeeDetailsOrigin.su || { ty: 'r', sm: {} },
                  s_r:
                    $scope.employeeDetailsOrigin.s_r != null
                      ? +($scope.employeeDetailsOrigin.s_r * 100).toFixed(2)
                      : Service.financialYearToSuperRate() * 100
                }

                const startDate = moment($scope.employeeDetailsOrigin.sd).format('DD/MM/YYYY')
                const [startDateDD, startDateMM, startDateYYYY] = startDate.split('/')

                $scope.employeeDetails3_edit = {
                  sd_dd: $scope.employeeDetailsOrigin.sd ? startDateDD : null,
                  sd_mm: $scope.employeeDetailsOrigin.sd ? startDateMM : null,
                  sd_yyyy: $scope.employeeDetailsOrigin.sd ? startDateYYYY : null,
                  t: $scope.employeeDetailsOrigin.t || null,
                  ty: $scope.employeeDetailsOrigin.ty || null,
                  wwh: $scope.employeeDetailsOrigin.wwh ?? null, // value can be 0
                  leaveLoading: r2(
                    $scope.employeeDetailsOrigin.ll ? Math.round($scope.employeeDetailsOrigin.ll * 10000) / 100 : 0
                  ),
                  pr: $scope.employeeDetailsOrigin.pr || null,
                  pu: $scope.employeeDetailsOrigin.pu || null,
                  pp: $scope.employeeDetailsOrigin.pp || 'a',
                  casualLoadingPercent: r2(
                    ($scope.employeeDetailsOrigin.cl == null
                      ? $rootScope.company.cl
                      : $scope.employeeDetailsOrigin.cl) * 100
                  )
                }

                // todo: get rid of incomeStreamType altogether
                $scope.employeeDetailsOrigin.tx.incomeStreamType ??= 'SAW' // bug: default value not set to SAW.
                const dateOfBirth = moment($scope.employeeDetailsOrigin.dob).format('DD/MM/YYYY')
                const [dd, mm, yyyy] = dateOfBirth.split('/')
                $scope.employeeDetails4_edit = {
                  dob_dd: $scope.employeeDetailsOrigin.dob ? dd : null,
                  dob_mm: $scope.employeeDetailsOrigin.dob ? mm : null,
                  dob_yyyy: $scope.employeeDetailsOrigin.dob ? yyyy : null,
                  tx: $scope.employeeDetailsOrigin.tx || {},
                  tfnd: $scope.employeeDetailsOrigin.tfnd || {}
                }

                const defaultValues = {
                  // make all input values start at 0.
                  ordinary: 0,
                  overtime: 0,
                  bonus: 0,
                  directorsFee: 0,
                  reimbursement: 0,
                  paidLeave: {
                    ancillary: 0,
                    // other: 0,
                    sickLeave: 0,
                    annualLeave: 0,
                    longServiceLeave: 0,
                    parental: 0,
                    workersCompensation: 0
                  },
                  allowance: {
                    transport: 0,
                    car: 0,
                    task: 0,
                    laundry: 0,
                    meals: 0,
                    qualification: 0,
                    travel: 0,
                    tool: 0,
                    other: 0,
                    odGeneral: 0,
                    odNonDeductible: 0,
                    odHomeOffice: 0,
                    odTransportFares: 0,
                    odUniform: 0,
                    odPrivateVehicle: 0
                  },
                  deduction: {
                    fees: 0,
                    fringeBenefit: 0,
                    workplaceGiving: 0
                  }
                }

                let openingBalance =
                  typeof $scope.employeeDetailsOrigin.ob === 'object' && $scope.employeeDetailsOrigin.ob !== null
                    ? $scope.employeeDetailsOrigin.ob
                    : {}
                $scope.employeeDetails5_edit = {
                  ob: {
                    ...defaultValues,
                    ...openingBalance,

                    al: openingBalance.al || 0,
                    lsl: openingBalance.lsl || 0,
                    sl: openingBalance.sl || 0,
                    ge: openingBalance.ge || 0,
                    tx: openingBalance.tx || 0,
                    s_g: openingBalance.s_g || 0,
                    s_ec: openingBalance.s_ec || 0,
                    at: openingBalance.at || new Date($scope.employeeDetailsOrigin.sd),
                    allowance: {
                      ...defaultValues.allowance,
                      ...openingBalance.allowance,
                      subjectToTax:
                        openingBalance.allowance && openingBalance.allowance.subjectToTax
                          ? openingBalance.allowance.subjectToTax
                          : openingBalance.allowance
                          ? (openingBalance.allowance.car || 0) +
                            (openingBalance.allowance.transport || 0) +
                            (openingBalance.allowance.laundry || 0) +
                            (openingBalance.allowance.meals || 0) +
                            (openingBalance.allowance.travel || 0) +
                            (openingBalance.allowance.other || 0)
                          : 0,
                      exemptFromTax: openingBalance?.allowance?.exemptFromTax || 0,
                      otherJobKeeper: openingBalance?.allowance?.otherJobKeeper || 0
                    },
                    deduction: {
                      ...defaultValues.deduction,
                      ...openingBalance.deduction,
                      fees: r2(openingBalance.deduction?.fees),
                      workplaceGiving: r2(openingBalance.deduction?.workplaceGiving),
                      // pr: {
                      //   F: r2(openingBalance.deduction?.pr?.F),
                      //   W: r2(openingBalance.deduction?.pr?.W)
                      // },
                      po: {
                        // post tax
                        F: r2(openingBalance.deduction?.po?.F),
                        W: r2(openingBalance.deduction?.po?.W),
                        G: r2(openingBalance.deduction?.po?.G),
                        D: r2(openingBalance.deduction?.po?.D)
                      }
                    }
                  }
                }
                let payTemplate = $scope.employeeDetailsOrigin.tm || {}
                $scope.employeeDetails6_edit = {
                  tm: {
                    ...payTemplate,
                    bo: payTemplate.bo || 0,
                    bo_s: !!payTemplate.bo_s,
                    eh: payTemplate.eh || [],
                    alw: payTemplate.alw || [],
                    ded: payTemplate.ded || [],
                    s_ec: payTemplate.s_ec || 0,
                    s_ss: payTemplate.s_ss || 0,
                    superSalarySacrificeType: payTemplate.superSalarySacrificeType || 'fiat',
                    fixedTax: payTemplate.fixedTax
                  },
                  fixedTaxEnabled: payTemplate.fixedTax != null
                }

                $scope.employeeDetails1_origin = angular.copy($scope.employeeDetails1_edit)
                $scope.employeeDetails2_origin = angular.copy($scope.employeeDetails2_edit)
                $scope.employeeDetails3_origin = angular.copy($scope.employeeDetails3_edit)
                $scope.employeeDetails4_origin = angular.copy($scope.employeeDetails4_edit)
                $scope.employeeDetails5_origin = angular.copy($scope.employeeDetails5_edit)
                $scope.employeeDetails6_origin = angular.copy($scope.employeeDetails6_edit)
              }

              initialize()

              // if(!$scope.employeeDetails.tx){
              // 	$scope.employeeDetails.tx = {};
              // }

              function checkBankAndSuperChanges() {
                const origin = cloneDeep($scope.employeeDetails2_origin)
                const edit = cloneDeep($scope.employeeDetails2_edit)

                // Ensure origin contribution rate is on the same conversion
                if (origin.s_r != null && origin.s_r < 1) {
                  origin.s_r = +(origin.s_r * 100).toFixed(2)
                } else {
                  origin.s_r = +origin.s_r.toFixed(2)
                }

                // Ensure edit contribution rate is on the same conversion
                if (edit.s_r != null) edit.s_r = +edit.s_r.toFixed(2)

                return angular.toJson(origin) == angular.toJson(edit)
              }

              $scope.cancel = function () {
                var isEquivalentObj1 =
                  angular.toJson($scope.employeeDetails1_origin) == angular.toJson($scope.employeeDetails1_edit)
                // var isEquivalentObj2 =
                //   angular.toJson($scope.employeeDetails2_origin) == angular.toJson($scope.employeeDetails2_edit)
                var isEquivalentObj2 = checkBankAndSuperChanges()
                var isEquivalentObj3 =
                  angular.toJson($scope.employeeDetails3_origin) == angular.toJson($scope.employeeDetails3_edit)
                var isEquivalentObj4 =
                  angular.toJson($scope.employeeDetails4_origin) == angular.toJson($scope.employeeDetails4_edit)
                var isEquivalentObj5 =
                  angular.toJson($scope.employeeDetails5_origin) == angular.toJson($scope.employeeDetails5_edit)
                var isEquivalentObj6 =
                  angular.toJson($scope.employeeDetails6_origin) == angular.toJson($scope.employeeDetails6_edit)

                if (
                  isEquivalentObj1 &&
                  isEquivalentObj2 &&
                  isEquivalentObj3 &&
                  isEquivalentObj4 &&
                  isEquivalentObj5 &&
                  isEquivalentObj6
                ) {
                  $uibModalInstance.dismiss()
                } else {
                  Service.confirm({
                    title: 'Confirm discard changes',
                    contents: 'Your changes have not been saved. Discard changes?',
                    confirmText: 'OK',
                    cancelText: 'Cancel',
                    confirmCallback: function () {
                      $uibModalInstance.dismiss()
                    },
                    cancelCallback: () => {}
                  })
                }
              }

              $scope.currentTab = currentTab || 'personal'
              // $scope.startDate = employeeDetailsOrigin.sd;
              $scope.employeeDetailsError = {}

              $scope.changeTab = function (toTab) {
                var isEquivalentObj
                switch ($scope.currentTab) {
                  case 'personal':
                    isEquivalentObj =
                      angular.toJson($scope.employeeDetails1_origin) == angular.toJson($scope.employeeDetails1_edit)
                    break
                  case 'bank':
                    isEquivalentObj =
                      angular.toJson($scope.employeeDetails2_origin) == angular.toJson($scope.employeeDetails2_edit)
                    break
                  case 'employment':
                    isEquivalentObj =
                      angular.toJson($scope.employeeDetails3_origin) == angular.toJson($scope.employeeDetails3_edit)
                    break
                  case 'tax':
                    isEquivalentObj =
                      angular.toJson($scope.employeeDetails4_origin) == angular.toJson($scope.employeeDetails4_edit)
                    break
                  case 'openingBalances':
                    isEquivalentObj =
                      angular.toJson($scope.employeeDetails5_origin) == angular.toJson($scope.employeeDetails5_edit)
                    break
                  case 'template':
                    isEquivalentObj =
                      angular.toJson($scope.employeeDetails6_origin) == angular.toJson($scope.employeeDetails6_edit)
                    break
                  default:
                    break
                }

                function changeTab() {
                  $scope.$evalAsync(function () {
                    $scope.currentTab = toTab
                    // original 상태로 돌려 놓는다.
                    $scope.employeeDetails1_edit = angular.copy($scope.employeeDetails1_origin)
                    $scope.employeeDetails2_edit = angular.copy($scope.employeeDetails2_origin)
                    $scope.employeeDetails2_edit = {
                      ...$scope.employeeDetails2_origin,
                      s_r:
                        $scope.employeeDetailsOrigin.s_r != null
                          ? +($scope.employeeDetailsOrigin.s_r * 100).toFixed(2)
                          : Service.financialYearToSuperRate() * 100
                    }
                    $scope.employeeDetails3_edit = angular.copy($scope.employeeDetails3_origin)
                    $scope.employeeDetails4_edit = angular.copy($scope.employeeDetails4_origin)
                    $scope.employeeDetails5_edit = angular.copy($scope.employeeDetails5_origin)
                    $scope.employeeDetails6_edit = angular.copy($scope.employeeDetails6_origin)
                  })
                }

                if (isEquivalentObj) {
                  $scope.currentTab = toTab
                } else {
                  Service.confirm({
                    title: 'Confirm discard changes',
                    contents: 'Your changes have not been saved. Discard changes?',
                    confirmText: 'OK',
                    cancelText: 'Cancel',
                    confirmCallback: function () {
                      changeTab()
                    },
                    cancelCallback: () => {}
                  })
                }
              }

              $scope.getHourlyRate = function () {
                const hourlyRate = getHourlyRate(
                  $scope.employeeDetails3_edit.ty,
                  $scope.employeeDetails3_edit.wwh,
                  $scope.employeeDetails3_edit.pr,
                  $scope.employeeDetails3_edit.pu,
                  $scope.employeeDetails3_edit.casualLoadingPercent
                )
                if (!hourlyRate) return null
                return hourlyRate.toFixed(2)
              }

              $scope.hourlyRateInfo = function () {
                hourlyRateInfo()
              }

              $scope.leaveLoadingInfo = function () {
                leaveLoadingInfo()
              }

              $scope.residencyChanged = function () {
                $scope.employeeDetails4_edit.tx.o_ft = false
                $scope.employeeDetails4_edit.tx.o_studentLoans = false
                $scope.employeeDetails4_edit.tx.o_shearer = false
              }

              $scope.reactState = {
                activateEmployee,
                updateEmployee: updateEmployeeServer,
                employee: $scope.employeeDetailsOrigin,
                rosterOnly: $scope.employeeDetailsOrigin.rosterOnly,
                status: $scope.employeeDetailsOrigin.s
              }

              function activateEmployee(rosterOnly, isStp2020) {
                // We want to first verify the employee is a STP 2020 employee
                // before we allow that employee to be activated on backend

                if (
                  $rootScope.company?.features?.beta2020 && // if not in beta, stay closed
                  $rootScope.company?.stp2020 && // we're already in STP V2
                  !isStp2020 // Employees resurrected from dead must follow new rules
                ) {
                  // show stp transition modal
                  $rootScope.stpTransitionState.callback = () => {
                    updateEmployeeServer(
                      {
                        s: 'a',
                        rosterOnly
                      },
                      'status'
                    )
                  }
                } else {
                  // do not show stp transition modal
                  updateEmployeeServer(
                    {
                      s: 'a',
                      rosterOnly
                    },
                    'status'
                  )
                }
              }

              function updateEmployeeServer(employeeDetails, formNumber) {
                if (formNumber === '5' && employeeDetails.ob && employeeDetails.ob.at) {
                  employeeDetails.ob.at = Service.getUTCDate(employeeDetails.ob.at)
                }

                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/employee/update/' + employeeDetailsOrigin._id,
                    data: {
                      companyId: $rootScope.company._id,
                      employee: employeeDetails
                    }
                  },
                  (res) => {
                    res.employee = Service.sanitizeObj(res.employee)
                    $scope.loading = false
                    EmployeeAction.update(res.employee)
                    $scope.employeeDetailsOrigin = res.employee
                    initialize()
                    $scope.stp2020ready = $scope.company.stp2020 && $scope.employeeDetailsOrigin.tx.stp2020

                    if (formNumber === '4') {
                      const updatedEmployeeIndex = $rootScope.employees.findIndex(
                        (employee) => employee._id === res.employee._id
                      )
                      const updatedEmployee = $rootScope.employees[updatedEmployeeIndex]
                      $rootScope.employees[updatedEmployeeIndex] = {
                        ...updatedEmployee,
                        s: 'a'
                      }
                    }

                    Service.alert('Success!', 'Employee details have been updated successfully!', 'success')
                    if (formNumber == 'status') {
                      $scope.loading = true
                      $scope.employeeDetailsOrigin.s = res.employee.s
                      $scope.reactState = {
                        ...$scope.reactState,
                        activateEmployee,
                        updateEmployee: updateEmployeeServer,
                        employee: res.employee,
                        status: res.employee.s,
                        rosterOnly: res.employee.rosterOnly
                      }
                      Http(
                        {
                          method: 'get',
                          api: '/v1/initial/'
                        },
                        async (res) => {
                          $ngRedux.dispatch(setInitialState(res))
                          $scope.loading = false
                        }
                      )
                    } else {
                      $scope['employeeDetails' + formNumber + '_origin'] = employeeDetails
                    }
                    if (successCallback) {
                      successCallback()
                    }
                  },
                  (err) => {
                    $scope.loading = false
                    if (errorCallback) {
                      errorCallback()
                    }
                  }
                )
              }
              $scope.updateEmployee1 = function () {
                // validation
                var valid = true
                $scope.employeeDetailsError1 = {}

                if (!$scope.employeeDetails1_edit.f) {
                  $scope.employeeDetailsError1.f = true
                  valid = false
                }

                if (!$scope.employeeDetails1_edit.l) {
                  $scope.employeeDetailsError1.l = true
                  valid = false
                }

                if (
                  !$scope.employeeDetails1_edit.e ||
                  !/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
                    $scope.employeeDetails1_edit.e
                  )
                ) {
                  $scope.employeeDetailsError1.e = true
                  valid = false
                }

                // To remove address and related fields for TSR
                const isTsr = sessionStorage.isTsrOnly === 'true'

                if (!$scope.employeeDetails1_edit.a_1 && !isTsr) {
                  $scope.employeeDetailsError1.a_1 = true
                  valid = false
                }
                if (!$scope.employeeDetails1_edit.a_c && !isTsr) {
                  $scope.employeeDetailsError1.a_c = true
                  valid = false
                }
                if (!$scope.employeeDetails1_edit.a_p && !isTsr) {
                  $scope.employeeDetailsError1.a_p = true
                  valid = false
                }
                if (!$scope.employeeDetails1_edit.a_s && !isTsr) {
                  $scope.employeeDetailsError1.a_s = true
                  valid = false
                }

                if (!valid) {
                  return false
                }

                updateEmployeeServer($scope.employeeDetails1_edit, '1')
              }

              $scope.updateEmployee2 = function () {
                // validation
                var valid = true
                $scope.employeeDetailsError2 = {}

                if ($scope.employeeDetails2_edit.pm == 'b' && !$scope.employeeDetails2_edit.b_n) {
                  $scope.employeeDetailsError2.b_n = true
                  valid = false
                }

                if (
                  $scope.employeeDetails2_edit.pm == 'b' &&
                  (!$scope.employeeDetails2_edit.b_b || !/^[0-9]{6}$/i.test($scope.employeeDetails2_edit.b_b))
                ) {
                  $scope.employeeDetailsError2.b_b = true
                  valid = false
                }

                if (
                  $scope.employeeDetails2_edit.pm == 'b' &&
                  (!$scope.employeeDetails2_edit.b_no || !/^[0-9-]{1,9}$/i.test($scope.employeeDetails2_edit.b_no))
                ) {
                  $scope.employeeDetailsError2.b_no = true
                  valid = false
                }

                if (
                  $scope.employeeDetails2_edit.s_r === '' ||
                  $scope.employeeDetails2_edit.s_r < 0 ||
                  $scope.employeeDetails2_edit.s_r > 100
                ) {
                  $scope.employeeDetailsError2.s_r = true
                  valid = false
                }

                if ($scope.employeeDetails2_edit.su.ty == 's') {
                  if (!$scope.employeeDetails2_edit.su.sm.n) {
                    $scope.employeeDetailsError2.selfManagedFundName = true
                    valid = false
                  }

                  if (
                    !$scope.employeeDetails2_edit.su.sm.abn ||
                    !Service.isABN($scope.employeeDetails2_edit.su.sm.abn)
                  ) {
                    $scope.employeeDetailsError2.selfManagedFundAbn = true
                    valid = false
                  }

                  // if( !$scope.employeeDetails2_edit.su.sm.esa) {
                  // 	$scope.employeeDetailsError2.selfManagedFundServiceAddress = true;
                  // 	valid = false;
                  // }

                  if (!$scope.employeeDetails2_edit.su.sm.b_n) {
                    $scope.employeeDetailsError2.selfSuperAccountName = true
                    valid = false
                  }

                  if (
                    !$scope.employeeDetails2_edit.su.sm.b_b ||
                    !/^[0-9]{6}$/i.test($scope.employeeDetails2_edit.su.sm.b_b)
                  ) {
                    $scope.employeeDetailsError2.selfSuperBsb = true
                    valid = false
                  }

                  if (
                    !$scope.employeeDetails2_edit.su.sm.b_no ||
                    !/^[0-9-]{1,9}$/i.test($scope.employeeDetails2_edit.su.sm.b_no)
                  ) {
                    $scope.employeeDetailsError2.selfSuperAccountNumber = true
                    valid = false
                  }
                }
                if (!valid) {
                  return false
                }

                let employeeEditData = {
                  ...$scope.employeeDetails2_edit,
                  s_r: parseFloat($scope.employeeDetails2_edit.s_r) / 100
                }

                updateEmployeeServer(employeeEditData, '2')
              }

              $scope.updateEmployee3 = function () {
                // validation
                var valid = true
                $scope.employeeDetailsError3 = {}

                if (!$scope.employeeDetails3_edit.t) {
                  $scope.employeeDetailsError3.t = true
                  valid = false
                }

                if (
                  !$scope.employeeDetails3_edit.sd_dd ||
                  $scope.employeeDetails3_edit.sd_dd < 1 ||
                  $scope.employeeDetails3_edit.sd_dd > 31
                ) {
                  $scope.employeeDetailsError3.sd_dd = true
                  valid = false
                }

                if (
                  !$scope.employeeDetails3_edit.sd_mm ||
                  $scope.employeeDetails3_edit.sd_mm < 1 ||
                  $scope.employeeDetails3_edit.sd_mm > 12
                ) {
                  $scope.employeeDetailsError3.sd_mm = true
                  valid = false
                }

                if (
                  !$scope.employeeDetails3_edit.sd_yyyy ||
                  $scope.employeeDetails3_edit.sd_yyyy < 1950 ||
                  $scope.employeeDetails3_edit.sd_yyyy > 2030
                ) {
                  if ($scope.employeeDetails3_edit.sd_yyyy < 1950) {
                    $scope.employeeDetailsError3.startDate =
                      'Employment Start Date must be later than the 1st of January 1950'
                  }
                  $scope.employeeDetailsError3.sd_yyyy = true
                  valid = false
                }

                if ($scope.employeeDetails3_edit.wwh === null || isNaN($scope.employeeDetails3_edit.wwh)) {
                  $scope.employeeDetailsError3.wwh = true
                  valid = false
                }

                if ($scope.employeeDetails3_edit.pr === null || isNaN($scope.employeeDetails3_edit.pr)) {
                  $scope.employeeDetailsError3.pr = true
                  valid = false
                }

                if (
                  $scope.employeeDetails3_edit.ty == 'c' &&
                  $scope.employeeDetails3_edit.casualLoadingPercent == null
                ) {
                  $scope.employeeDetailsError3.casualLoading = true
                  valid = false
                }

                if (!valid) {
                  return false
                }

                // calculate hourly rate
                $scope.employeeDetails3_edit.r = getHourlyRate(
                  $scope.employeeDetails3_edit.ty,
                  $scope.employeeDetails3_edit.wwh,
                  $scope.employeeDetails3_edit.pr,
                  $scope.employeeDetails3_edit.pu,
                  $scope.employeeDetails3_edit.casualLoadingPercent
                )

                $scope.employeeDetails3_edit.cl =
                  $scope.employeeDetails3_edit.casualLoadingPercent && $scope.employeeDetails3_edit.casualLoadingPercent
                    ? $scope.employeeDetails3_edit.casualLoadingPercent / 100
                    : 0
                $scope.employeeDetails3_edit.ll =
                  $scope.employeeDetails3_edit.leaveLoading && $scope.employeeDetails3_edit.leaveLoading > 0
                    ? $scope.employeeDetails3_edit.leaveLoading / 100
                    : 0

                updateEmployeeServer($scope.employeeDetails3_edit, '3')
              }

              $scope.saveDeclarationDate = function (status) {
                if (!$scope.employeeDetails4_edit.tfnd || !$scope.employeeDetails4_edit.tfnd.p) {
                  $scope.employeeDetails4_edit.tfnd.s_at = Service.getUTCDate()
                }
              }

              $scope.getWorkHoursInTemplate = function () {
                var payPeriod =
                  !$scope.employeeDetails3_edit.pp || $scope.employeeDetails3_edit.pp == 'a'
                    ? $rootScope.company.pp
                    : $scope.employeeDetails3_edit.pp
                return (
                  $scope.employeeDetails3_edit.wwh *
                  (payPeriod == 'w' ? 1 : payPeriod == 'f' ? 2 : payPeriod == 'q' ? 52 / 4 : 52 / 12)
                )
              }

              $scope.updateEmployee4 = function () {
                // validation
                var valid = true
                $scope.employeeDetailsError4 = {}

                if (
                  ($scope.employeeDetails4_edit.dob_dd &&
                    ($scope.employeeDetails4_edit.dob_dd < 1 || $scope.employeeDetails4_edit.dob_dd > 31)) ||
                  (($scope.employeeDetails4_edit.dob_mm ||
                    $scope.employeeDetails4_edit.dob_yyyy ||
                    ($scope.employeeDetails4_edit.tfnd && $scope.employeeDetails4_edit.tfnd.p)) &&
                    !$scope.employeeDetails4_edit.dob_dd)
                ) {
                  $scope.employeeDetailsError4.dob_dd = true
                  valid = false
                }

                if (
                  ($scope.employeeDetails4_edit.dob_mm &&
                    ($scope.employeeDetails4_edit.dob_mm < 1 || $scope.employeeDetails4_edit.dob_mm > 12)) ||
                  (($scope.employeeDetails4_edit.dob_dd ||
                    $scope.employeeDetails4_edit.dob_yyyy ||
                    ($scope.employeeDetails4_edit.tfnd && $scope.employeeDetails4_edit.tfnd.p)) &&
                    !$scope.employeeDetails4_edit.dob_mm)
                ) {
                  $scope.employeeDetailsError4.dob_mm = true
                  valid = false
                }

                if (
                  ($scope.employeeDetails4_edit.dob_yyyy &&
                    ($scope.employeeDetails4_edit.dob_yyyy < 1900 || $scope.employeeDetails4_edit.dob_yyyy > 2018)) ||
                  (($scope.employeeDetails4_edit.dob_mm ||
                    $scope.employeeDetails4_edit.dob_dd ||
                    ($scope.employeeDetails4_edit.tfnd && $scope.employeeDetails4_edit.tfnd.p)) &&
                    !$scope.employeeDetails4_edit.dob_yyyy)
                ) {
                  $scope.employeeDetailsError4.dob_yyyy = true
                  valid = false
                }

                if (
                  (!$scope.employeeDetails4_edit.tx || !$scope.employeeDetails4_edit.tx.txe) &&
                  $scope.TFNCheck() == 'invalid'
                ) {
                  $scope.employeeDetailsError4.tfn = true
                  valid = false
                }

                if (!valid) {
                  return false
                }

                switch ($scope.employeeDetails4_edit.tx.txe) {
                  case 'no':
                    $scope.employeeDetails4_edit.tx.no = '000000000'
                    break
                  case 'tp':
                    $scope.employeeDetails4_edit.tx.no = '111111111'
                    break
                  case '18':
                    $scope.employeeDetails4_edit.tx.no = '333333333'
                    break
                  case 'ps':
                    $scope.employeeDetails4_edit.tx.no = '444444444'
                    break
                  default:
                    break
                }

                updateEmployeeServer($scope.employeeDetails4_edit, '4', () => {
                  $scope.cancel()
                })
              }
              $scope.showPaidLeave = true
              $scope.showLumpSum = true
              $scope.showFringeBenefit = true
              $scope.showAllowance =
                $scope.employeeDetails5_edit?.ob?.allowance?.subjectToTax > 0 ||
                $scope.employeeDetails5_edit?.ob?.allowance?.exemptFromTax > 0 ||
                $scope.employeeDetails5_edit?.ob?.allowance?.otherJobKeeper > 0
              $scope.showDeduction = true
              // $scope.employeeDetails5_edit?.ob?.deduction?.fees > 0 ||
              // $scope.employeeDetails5_edit?.ob?.deduction?.workplaceGiving > 0

              $scope.updateEmployee5 = function () {
                let valid = true
                $scope.employeeDetailsError5 = {}

                let openingBalanceValidationCheck = (entityName1, entityName2) => {
                  if (!entityName2) {
                    if (
                      !$scope.employeeDetails5_edit.ob ||
                      isNaN($scope.employeeDetails5_edit.ob[entityName1]) ||
                      $scope.employeeDetails5_edit.ob[entityName1] < 0
                    ) {
                      $scope.employeeDetailsError5[entityName1] = true
                      valid = false
                    }
                  } else {
                    if (
                      !$scope.employeeDetails5_edit.ob ||
                      !$scope.employeeDetails5_edit.ob[entityName1] ||
                      isNaN($scope.employeeDetails5_edit.ob[entityName1][entityName2]) ||
                      $scope.employeeDetails5_edit.ob[entityName1][entityName2] < 0
                    ) {
                      $scope.employeeDetailsError5[entityName1 + '_' + entityName2] = true
                      valid = false
                    }
                  }
                }

                if (!$scope.employeeDetails5_edit.ob || !$scope.employeeDetails5_edit.ob.at) {
                  Service.alert('', 'As at field is required.', 'error')
                  $scope.employeeDetailsError5.datePicker = true
                  valid = false
                } else {
                  $scope.employeeDetails5_edit.ob.at = new Date($scope.employeeDetails5_edit.ob.at)
                }

                // return false;
                if (!$scope.employeeDetails5_edit.ob || isNaN($scope.employeeDetails5_edit.ob.al)) {
                  $scope.employeeDetailsError5.al = true
                  valid = false
                }

                if (
                  $rootScope.company?.le?.lsl &&
                  (!$scope.employeeDetails5_edit.ob || isNaN($scope.employeeDetails5_edit.ob.lsl))
                ) {
                  $scope.employeeDetailsError5.lsl = true
                  valid = false
                }

                if (!$scope.employeeDetails5_edit.ob || isNaN($scope.employeeDetails5_edit.ob.sl)) {
                  $scope.employeeDetailsError5.sl = true
                  valid = false
                }

                openingBalanceValidationCheck('ge')
                openingBalanceValidationCheck('tx')
                openingBalanceValidationCheck('s_g')
                openingBalanceValidationCheck('s_ec')
                // openingBalanceValidationCheck('ordinary')

                // openingBalanceValidationCheck('allowance', 'subjectToTax')
                // openingBalanceValidationCheck('allowance', 'exemptFromTax')
                // openingBalanceValidationCheck('allowance', 'otherJobKeeper')

                // openingBalanceValidationCheck('deduction', 'fees')
                // openingBalanceValidationCheck('deduction', 'workplaceGiving')
                // openingBalanceValidationCheck('deduction', 'childSupportGarnish')
                // openingBalanceValidationCheck('deduction', 'childSupportDeduction')

                if (!valid) {
                  return false
                }

                updateEmployeeServer($scope.employeeDetails5_edit, '5')
              }

              $scope.updateEmployee6 = function () {
                if (!$scope.employeeDetails6_edit.fixedTaxEnabled || $scope.employeeDetails6_edit.tm.fixedTax == null) {
                  delete $scope.employeeDetails6_edit.tm.fixedTax
                }

                if (
                  $scope.employeeDetails6_edit.tm.superSalarySacrificeType === 'percentage' &&
                  $scope.employeeDetails6_edit.tm.s_ss > 100
                ) {
                  Service.alert('Error!', 'Super salary sacrifice percentage cannot be more than 100%', 'error')
                  return false
                }

                updateEmployeeServer($scope.employeeDetails6_edit, '6')
              }

              $scope.addExtraHoursToTemplate = function () {
                $scope.employeeDetails6_edit.tm.eh.push({
                  n: '',
                  h: '',
                  r: '',
                  ov: false
                })
              }

              $scope.deleteExtraHoursFromTemplate = function (index) {
                $scope.employeeDetails6_edit.tm.eh.splice(index, 1)
              }

              $scope.toggleSuperSacrificeType = function (type) {
                $scope.employeeDetails6_edit.tm.superSalarySacrificeType = type === 'fiat' ? 'percentage' : 'fiat'
              }

              $scope.addAllowanceToTemplate = function () {
                $scope.employeeDetails6_edit.tm.alw.push({
                  u: 0,
                  r: 0,
                  n: '',
                  o: 'ts',
                  ty: $scope.stp2020ready ? 'CD' : 'o'
                })
              }

              $scope.deleteAllowanceFromTemplate = function (parentIndex, index) {
                $scope.employeeDetails6_edit.tm.alw.splice(index, 1)
              }

              $scope.addDeductionToTemplate = function (x) {
                $scope.employeeDetails6_edit.tm.ded.push(
                  x || {
                    a: 0,
                    n: '',
                    o: 'pr',
                    ty: $scope.stp2020ready ? 'F' : 'f'
                  }
                )
              }

              $scope.deleteDeductionFromTemplate = function (parentIndex, index) {
                $scope.employeeDetails6_edit.tm.ded.splice(index, 1)
              }

              switch ($scope.employeeDetails4_edit.tx.txe) {
                case 'no':
                  $scope.employeeDetails4_edit.tx.no = '000000000'
                  break
                case 'tp':
                  $scope.employeeDetails4_edit.tx.no = '111111111'
                  break
                case '18':
                  $scope.employeeDetails4_edit.tx.no = '333333333'
                  break
                case 'ps':
                  $scope.employeeDetails4_edit.tx.no = '444444444'
                  break
                default:
                  break
              }

              $scope.TFNCheck = function () {
                if (Service.TFNCheck($scope.employeeDetails4_edit.tx.no)) {
                  return 'valid'
                } else {
                  return 'invalid'
                }
              }
              // $scope.searchSuper method 안에 에러 수정하기 위해
              $scope.employeeDetailsError2 = {}

              $scope.searchSuper_edit = function (superValue) {
                if (!superValue) {
                  $scope.employeeDetailsError2.superFundName = true
                  return false
                } else {
                  $scope.employeeDetailsError2.superFundName = false
                }
                $scope.superSearching = true
                Http(
                  {
                    method: 'get',
                    api: '/super/search/' + superValue,
                    forceSend: true
                  },
                  (res) => {
                    $scope.superSearching = false
                    var superList = []
                    for (const key in res) {
                      if (res.hasOwnProperty(key)) {
                        const element = res[key]
                        if (!isNaN(key)) {
                          superList.push(element)
                        }
                      }
                    }
                    $scope.superList = superList

                    // 저장된 employee data 를 update 한다.
                  },
                  (err) => {
                    $scope.superSearching = false
                  }
                )
              }

              $scope.selectSuper = function (superFundDetails) {
                $scope.employeeDetails2_edit.su.usi = superFundDetails.uniqueSuperannuationIdentifier
                $scope.employeeDetails2_edit.su.n = superFundDetails.productName
                $scope.employeeDetails2_edit.su.abn = superFundDetails.australianBusinessNumber
                $scope.superList = []
              }

              $scope.deleteEmployee = function () {
                Service.confirm({
                  title: 'Delete your employee',
                  contents: `Deleting your employee will remove them from the People section.
                    If you need to view your deleted employees, select the three dots next to 'People'.<br><br> 
                    Would you like to proceed?`,
                  confirmText: 'Yes!',
                  cancelText: 'No',
                  confirmCallback: function () {
                    $uibModalInstance.dismiss()
                    Http(
                      {
                        method: 'post',
                        api: '/v1/employee/delete/' + employeeDetailsOrigin._id
                      },
                      (res) => {
                        $scope.loading = false
                        // 저장된 employee data 를 update 한다.
                        employeeDetailsOrigin.s = 'd'
                        EmployeeAction.update(employeeDetailsOrigin)
                        LeaveAction.deleteEmployee(employeeDetailsOrigin._id)
                      },
                      (err) => {
                        $scope.loading = false
                      }
                    )
                  },
                  cancelCallback: () => {}
                })
              }

              $scope.resendInvitation = function () {
                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/resend-invitation/',
                    data: {
                      employee: employeeDetailsOrigin
                    }
                  },
                  () => {
                    $scope.loading = false
                    Service.alert('Success!', 'Invitation has been successfully sent!', 'success')
                    Analytics.logEvent('Send Employee Invitation')
                  },
                  (err) => {
                    $scope.loading = false
                  }
                )
              }

              $scope.superGuaranteeInfo = () => {
                Service.infoPopup({
                  title: 'Remove super guarantee threshold',
                  contents:
                    'By ticking this box, super guarantee amount will be calculated on all pay below the super guarantee monthly threshold ($450).'
                })
              }

              $scope.onClickHomeCountry = () => {
                Service.infoPopup({
                  title: 'Home country',
                  contents:
                    'The issuing country of the passport they used to enter Australia. This applies only to Working Holiday Makers.'
                })
              }

              $scope.clearSuper = () => {
                $scope.employeeDetails2_edit.su.ty = 'r'
                $scope.employeeDetails2_edit.su.usi = null
                $scope.employeeDetails2_edit.su.abn = null
                $scope.employeeDetails2_edit.su.n = null
                $scope.employeeDetails2_edit.su.no = null
                $scope.employeeDetails2_edit.su.removedSGThreshold = false
                $scope.employeeDetails2_edit.su.sm = {}
              }

              $scope.incomeTypeInfo = incomeTypeInfo
            }
          ],
          { size: 'xl' }
        )
      }

      function incomeTypeInfo() {
        Service.infoPopup({
          title: 'Income type',
          contents: `
            <p>                
              Income types have been added to STP Phase 2 to help the ATO identify any concessional reporting options (such as closely held payees). The following income types are available on Payroller:
            </p>
            <p>
              <b>Salary and wages</b>: Assessable income paid to payees for work performed in Australia, other than that included as other Income Types.
            </p>
            <p>
              <b>Closely held payees</b>: A payee who is directly related to the entity, from which they receive payments, such as family members of a family business; directors or shareholders of a company; beneficiaries of a trust.
            </p>
            <p>
              <b>Seasonal Worker Programme</b>: Employees hired under the Seasonal Worker Programme. This programme offers employers in the agriculture sector and employers in selected locations in the accommodation sector access to a reliable, returning workforce when there is not enough local Australian labour to meet seasonal demand.
            </p>
            <p>
              To indicate that your employee is a <b>Horticulturalist and Shearer</b>, select this option in the <b>Status of Residency</b> dropdown menu.
            </p>
          `
        })
      }

      function leaveLoadingInfo() {
        Service.infoPopup({
          title: 'Leave Loading',
          contents:
            'Leave loading is an extra payment on top of your annual leave pay. It is usually 17.5% of your normal pay. <br><br>Your award, enterprise agreement or contract will state if you are entitled to leave loading. Not all employees are entitled to leave loading. <br><br>If you are not sure whether you are entitled to leave loading, or how much, you should get legal advice.'
        })
      }

      function hourlyRateInfo() {
        Service.infoPopup({
          title: 'Casual loading rate',
          contents:
            "The equivalent rate differs because of the casual loading. Casual employees are entitled to a higher rate because they don't get benefits like 'sick leave' or 'annual leave'."
        })
      }

      function getHourlyRate(type, weeklyWorkHours, payRate, payUnit, employCasualLoadingPercent) {
        if (!type || !weeklyWorkHours || !payRate || !payUnit) {
          return null
        }
        var standardHourlyRate
        switch (payUnit) {
          case 'h':
            standardHourlyRate = payRate
            break
          case 'd':
            standardHourlyRate = payRate / ($rootScope.company.wh || 7.6)
            break
          case 'y':
            standardHourlyRate = payRate / 52 / weeklyWorkHours
            break

          default:
            return null
        }

        var hourlyRate
        // casual 인지 permanent 인지
        if (type == 'c') {
          hourlyRate =
            standardHourlyRate * (1 + (employCasualLoadingPercent != null ? employCasualLoadingPercent / 100 : 0))
        } else {
          hourlyRate = standardHourlyRate
        }
        return Math.round(hourlyRate * 100000) / 100000
      }

      return {
        new: newEmployee,
        edit: editEmployee
      }
    }
  )
