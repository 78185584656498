import clsx from 'clsx'
import React from 'react'
import * as s from './styles'

export function CheckItem({ feature, isV2 }) {
  const path = isV2 ? '/static/images/' : '/img/paywall/'
  return (
    <div className={s.checkItem}>
      <img width={'22px'} height={'22px'} src={feature.available ? `${path}check.svg` : `${path}check-inactive.png`} />
      <span
        className={clsx(s.checkTitle, {
          '!text-[#ADADAD]': !feature.available
        })}
      >
        {feature.featureTitle}
      </span>
    </div>
  )
}
